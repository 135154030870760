import React, { useState } from "react";
import Header from "./Header";
import Box from "@mui/material/Box";
import {List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import colors from "../../../utils/constant/colors";
import { useLocation, useNavigate } from "react-router-dom";
import routerPath from "../../../utils/router/routerPath";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupAdd from "@mui/icons-material/GroupAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../../store/session";
import { toast } from "react-toastify";
import { logOutUser } from "../../../utils/apis/apis";
import ComputerIcon from "@mui/icons-material/Computer";
const Layout = ({ children, buttonName, showSideBar, active, showAdmin ,isLeave}) => {
  const [activeItem, setActiveItem] = useState(0);
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const navigate = useNavigate();
  const location = useLocation();
  const handleItemClick = (path) => {
    setActiveItem(path);
    navigate(path);
  };
  const userRoleFromLocalStorage = localStorage.getItem("user");
  const convertData = JSON.parse(userRoleFromLocalStorage);
  const sidebarItems =
    convertData?.user_role === "admin_user"
      ? [
          {
            path: routerPath.home,
            icon: (val) => (
              <HomeIcon
                sx={{
                  color:
                    active == 0
                      ? colors.white
                      : location.pathname === val
                      ? colors.white
                      : "",
                }}
              />
            ),
          },
          {
            path: routerPath.events,
            icon: (val) => (
              <ComputerIcon
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
          {
            path: routerPath.normaluser,
            icon: (val) => (
              <PeopleAltIcon
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
          {
            path: routerPath.webinarGhostUser,
            icon: (val) => (
              <GroupAdd
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
          {
            path: routerPath.setting,
            icon: (val) => (
              <SettingsIcon
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
        ]
      : [
          {
            path: routerPath.home,
            icon: (val) => (
              <HomeIcon
                sx={{
                  color:
                    active == 0
                      ? colors.white
                      : location.pathname === val
                      ? colors.white
                      : "",
                }}
              />
            ),
          },
          {
            path: routerPath.events,
            icon: (val) => (
              <ComputerIcon
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
          {
            path: routerPath.webinarGhostUser,
            icon: (val) => (
              <GroupAdd
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
          {
            path: routerPath.setting,
            icon: (val) => (
              <SettingsIcon
                sx={{
                  color: location.pathname === val ? colors.white : "",
                }}
              />
            ),
          },
        ];
  const sideNavStyle = {
    minWidth: 0,
    justifyContent: "center",
    padding: "10px",
  };

  const logout = async () => {
    try {
      const formData = new FormData();
      formData.append("refresh_token", sessionUser.refresh);
      await logOutUser(sessionUser.access, formData)
        .then((value) => {
          if (value.status === "success") {
            dispatch(sessionActions.logout());
            toast.success(value.message);
            localStorage.removeItem("webinarId");
            localStorage.removeItem("user");
            navigate(routerPath.login);
            window.location.reload(true);
          }
        })
        .catch((err) => {
          dispatch(sessionActions.logout());
          toast.success("Logout successful");
          localStorage.removeItem("webinarId");
          localStorage.removeItem("user");
          navigate(routerPath.login);
          window.location.reload(true);
        });
    } catch (error) {
      dispatch(sessionActions.logout());
      toast.success("Logout successful");
      localStorage.removeItem("webinarId");
      localStorage.removeItem("user");
      navigate(routerPath.login);
      window.location.reload(true);
    }
  };
  return (
    <>
      <Header buttonName={buttonName} />
      {showSideBar ? (
        <Box sx={{ display: showSideBar ? "flex" : "contents" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              borderRight: 1,
              borderColor: colors.inactive,
            }}
          > 
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                {sidebarItems.map((item, index) => (
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                    }}
                    onClick={() => handleItemClick(item.path)}
                    key={index}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        padding: "10px",
                        borderRadius: "10px",
                        backgroundColor:
                          active == 0 && index == 0
                            ? colors.primary
                            : location.pathname === item.path
                            ? colors.primary
                            : "",
                      }}
                    >
                      {item.icon(item.path)}
                    </ListItemIcon>
                  </ListItemButton>
                ))}

                <ListItemButton
                  sx={{
                    minHeight: 48,

                    px: 2.5,
                  }}
                  onClick={logout}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      borderRadius: "10px",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <Box>{children}</Box>
        </Box>
      ) : (
        <Box>{children}</Box>
      )}
    </>
  );
};

export default Layout;
