import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DetailsIcon from "@mui/icons-material/Details";
import VideocamIcon from "@mui/icons-material/Videocam";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import MoreIcon from "@mui/icons-material/More";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "../events/style.css";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const EventsLive = ({
  values,
  errors,
  setFieldValue,
  editable,
  setShowForm,
  showForm,
}) => {
  const [chatData, setChatData] = useState([]);

  const handleCheckboxToggle = (resolution) => {};

  const handleToggleSwitch = (resolution, isChecked) => {
    const updatedChatData = [...chatData];

    if (isChecked && !updatedChatData.includes(resolution)) {
      updatedChatData.push(resolution);
    } else if (!isChecked && updatedChatData.includes(resolution)) {
      const index = updatedChatData.indexOf(resolution);
      updatedChatData.splice(index, 1);
    }
    setChatData(updatedChatData);
    setFieldValue("resolution_status", updatedChatData);
  };
  const [value, setValue] = React.useState(0);
  const isData = editable
    ? values.webinarScheduleDMW
    : {
        daily: false,
        monthly: false,
        weekly: false,
      };
  console.log("valuesvalues", values);
  const [switches, setSwitches] = React.useState(isData);
  const handleSwitchToggle = (switchName) => {
    setSwitches((prevSwitches) => {
      const updatedSwitches = { daily: false, monthly: false, weekly: false };
      updatedSwitches[switchName] = !prevSwitches[switchName];
      if (updatedSwitches[switchName]) {
        Object.keys(updatedSwitches).forEach((name) => {
          if (name !== switchName) {
            updatedSwitches[name] = false;
          }
        });
      }
      setFieldValue("webinarScheduleDMW", updatedSwitches);
      return updatedSwitches;
    });
  };
  useEffect(() => {
    setChatData(values.resolution_status);
  }, [values.resolution_status]);
  useEffect(() => {
    setSwitches(values.webinarScheduleDMW);
  }, [values.webinarScheduleDMW]);
  const utcDateTimeString = values.schedule_time;
  const utcDateTime = new Date(utcDateTimeString);
  const [istDateTime, setIstDateTime] = React.useState(null);
  React.useEffect(() => {
    const istDateTimeString = utcDateTime.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });

    setIstDateTime(istDateTimeString);
  }, [utcDateTime]);

  const initialDate = dayjs();
  const [date, setDate] = React.useState(
    dayjs(values.schedule_time || initialDate)
  );
  useEffect(() => {
    setDate(dayjs(values.schedule_time));
  }, [values.schedule_time]);

  return (
    <>
      {errors.webinarScheduleDMW && (
        <div style={{ color: "red" }}>
          {errors.webinarScheduleDMW.schedule_time}
        </div>
      )}
      <Box className="container-live">
        <div className="heading-events">
          <Typography variant="h4" gutterBottom style={{ textAlign: "start" }}>
            Webinar Watch Room Setting
          </Typography>
        </div>
        <span style={{ color: "red", fontSize: "13px" }}>
          {errors.schedule_time}
        </span>
        <div
          className="container-toggleLive"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 18px 0px 0px 0px",
          }}
        >
          <div className="button-container">
            <Typography className="heading-toggletxt">
              Select Date & Time
            </Typography>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              style={{ marginTop: "2px" }}
            >
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  ampm={false}
                  disablePast
                  format="L HH:mm"
                  label="Select Webinar Date & Time"
                  value={dayjs(values.schedule_time)}
                  defaultValue={dayjs(values.schedule_time)}
                  onChange={(newValue) => {
                    const formattedDate = dayjs(newValue).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    );
                    setFieldValue("schedule_time", formattedDate);
                    setDate(newValue);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <span style={{ color: "red", fontSize: "13px" }}>
          {errors.webinarScheduleDMW}
        </span>
        <div
          className="container-toggleLive"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 18px 0px 0px 0px",
          }}
        >
          <div className="button-container">
            <Typography className="heading-toggletxt">Daily Webinar</Typography>
          </div>

          <div>
            <Switch
              checked={switches.daily}
              onChange={() => handleSwitchToggle("daily")}
              color="primary"
            />
          </div>
        </div>
        <div
          className="container-toggleLive"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 18px 0px 0px 0px",
          }}
        >
          <div className="button-container">
            <Typography className="heading-toggletxt">
              Weekly Webinar
            </Typography>
          </div>

          <div>
            <Switch
              checked={switches.weekly}
              onChange={() => handleSwitchToggle("weekly")}
              color="primary"
            />
          </div>
        </div>
        <div
          className="container-toggleLive"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: " 18px 0px 0px 0px",
          }}
        >
          <div className="button-container">
            <Typography className="heading-toggletxt">
              Monthly Webinar
            </Typography>
          </div>

          <div>
            <Switch
              checked={switches.monthly}
              onChange={() => handleSwitchToggle("monthly")}
              color="primary"
            />
          </div>
        </div>
        {showForm && (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            display="flex"
            alignItems="center"
            mt={3}
          >
            <Grid item xs={4}>
              <Typography>Select Video Resolutions</Typography>
            </Grid>
            <div></div>
            <Grid item xs={8} textAlign="right">
              <Grid container>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatData.includes(0)}
                        onChange={(event) =>
                          handleToggleSwitch(0, event.target.checked)
                        }
                      />
                    }
                    label="352x240"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatData.includes(1)}
                        onChange={(event) =>
                          handleToggleSwitch(1, event.target.checked)
                        }
                      />
                    }
                    label="824x480"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatData.includes(2)}
                        onChange={(event) =>
                          handleToggleSwitch(2, event.target.checked)
                        }
                      />
                    }
                    label="1280x720"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={chatData.includes(3)}
                        onChange={(event) =>
                          handleToggleSwitch(3, event.target.checked)
                        }
                      />
                    }
                    label="1920x1080"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default EventsLive;
