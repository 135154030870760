import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import "./style.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Layout from "../common/layout";
import Edit from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import {useNavigate } from "react-router-dom";
import {
  UserSignUp,

  creteDummyUser,
  getNormalUserList,
  getSearchNormalUsersData,
  updateNormalUser,
} from "../../utils/apis/apis";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import colors from "../../utils/constant/colors";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import { IoSearchSharp } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import constvalue from "../../utils/constant/constvalue";
import { Helmet } from "react-helmet";
const style = {
  marginTop: "20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
// const validationSchema = Yup.object({
//   email: Yup.string()
//     .email("Invalid email address")
//     .required("Please Enter Email"),
//   firstName: Yup.string().required("Please Enter First Name"),
//   lastName: Yup.string().required("Please Enter Last Name"),
//   phone: Yup.string().required("Please Enter Phone Number"),
//   password: Yup.string().required("Please Enter Password"),
//   confirmPassword: Yup.string()
//     .oneOf([Yup.ref("password"), null], "Passwords must match")
//     .required("Please Enter Confirm Password"),
// });
export default function NormalUser() {

  const [userData, setUserData] = useState([]);
  const [show, setShow] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const [showeditmodal, setShowEditmodal] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);

  const [totalDataSearch, setTotalDataSearch] = React.useState(0);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    passwordConfirm: "",
    is_superuser: "",
    edit: "",
    id: "",
  });
  const [switches, setSwitches] = useState({
    daily: false,
  });
  console.warn(switches);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [searchVal, setSearchVal] = useState([]);
  const [page, setPage] = React.useState(1);
  const handleSwitchToggle = (key) => {
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [key]: !prevSwitches[key],
    }));
  };
  const [normalPage, setNormalPage] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [showPage, setShowPage] = useState();
  const [showPageSea, setShowPageSea] = useState();
  const [totalData, setTotalData] = useState(0);
  const [searchVisible, setSearchVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUserPagination = (event, page) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    setNormalPage(page);
    if (page === totalPages) {
      getNormalUserData(page + 1);
    }
  };
  const getNormalUserData = async (page) => {
    try {
      setLoading(true);
      const nextPageToFetch = nextPage || page;
      const value = await getNormalUserList(
        sessionUser.access,
        nextPageToFetch
      );
      setTotalRows(value.total_pages);
      setTotalData(value.total_normal_user_length);
      setShowPage(true);
      setSearchVisible(false);
      if (value?.data?.length > 0) {
        setUserData(value.data);
        setLoading(false);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log("Error in fetching code", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getNormalUserData(normalPage);
  }, [normalPage]);
  const handleSearchPagination = (event, page) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    if (totalRows === 0) {
      setNormalPage(0);
      return;
    }
    setNormalPage(page);
    if (page === totalPages) {
      handleSearch(page + 1);
    }
  };
  const handleSearch = async (e) => {
    try {
      const inputVal = e.target.value;
      setSearchName(inputVal);
      if (inputVal.trim() === "") {
        setShowPage(true);
        setShowPageSea(false);
        getNormalUserData();
      } else {
        setShowPage(false);
        setShowPageSea(true);
        setSearchVisible(true);
        const nextPageToFetch = nextPage || page;
        const searchResults = await getSearchNormalUsersData(
          sessionUser.access,
          inputVal,
          nextPageToFetch
        );

        if (
          searchResults &&
          searchResults.status === "success" &&
          searchResults.data.length > 0
        ) {
          setUserData(searchResults.data);
          setTotalRows(searchResults.total_pages);
          setTotalDataSearch(searchResults.total_length);
        } else if (
          searchResults &&
          searchResults.status === "success" &&
          searchResults.data.length === 0 &&
          userData.length == 0
        ) {
          setUserData([]);
          setTotalRows(0);
          setShowPageSea(false);
          setShowPage(false);
          setTotalDataSearch(0);
        } else if (searchResults.message === "data not found") {
          setTotalDataSearch(0);
          setUserData([]);
        } else {
          setShowPageSea(false);
          setShowPage(false);
          setUserData([]);
        }
      }
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const addUsers = () => {
    if (formData.first_name == "") {
    } else if (formData.last_name == "") {
    } else {
      if (formData.edit == "add") {
        getNormalUserData();
        setShow(!show);
      } else {
      }
      setShow(!show);
    }
  };
  const updateUser = async (id) => {
    setLoading(false);
    setBtnVisible(true);
    const updateData = {
      first_name: formDataEdit.first_name,
      last_name: formDataEdit.last_name,
      email: formDataEdit.email,
      phone_number: formDataEdit.phone_number,
      is_superuser: formDataEdit.is_superuser,
      is_active: formDataEdit.is_active,
    };
    try {
      const value = await updateNormalUser(
        sessionUser.access,
        updateData,
        formDataEdit.id
      );
      if (value.status === "success") {
        toast.success(value.message);
        getNormalUserData();
        setTimeout(() => {
          setBtnVisible(true);
          setShowEditmodal(false);
          setBtnVisible(true);
        }, 1000);
      } else {
        toast.error(value.message);
      }
    } catch (err) {
      toast.error("Try after some time?");
    }
  };
  const handleInputChangeUpdate = (field, value) => {
    setFormDataEdit((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const createUser = async (id) => {
    const creteData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
    };
    try {
      const value = await creteDummyUser(sessionUser.access, creteData);
      if (value.status === "success") {
        toast.success(value.message);
        getNormalUserData();
      } else {
        toast.error(value.message);
      }
    } catch (err) {
      toast.error("Try after some time?");
    }
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      passwordConfirm: "",
      ActiveUser: "",
      is_superuser: true,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("You must enter first name"),
      last_name: Yup.string().required("You must enter last name"),
      phone_number: Yup.string()
        .matches(/^[0-9]*$/, "Phone number must contain only numeric digits")
        .required("You must enter phone number")
        .max(10, "10 digits required."),
      email: Yup.string()
        .email("You must enter a valid email")
        .matches(constvalue.regexEmail, "You must enter a valid email")
        .required("You must enter an email"),

      password: Yup.string()
        .required("Please enter your password.")
        .min(8, "Password is too short - should be 8 characters minimum."),
      passwordConfirm: Yup.string()
        .required("Please enter your Confirm password.")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("first_name", values.first_name);
        formData.append("last_name", values.last_name);
        formData.append("email", values.email);
        formData.append("phone_number", values.phone_number);
        formData.append("password", values.password);
        formData.append("passwordConfirm", values.passwordConfirm);
        formData.append("is_superuser", values.is_superuser);
        await UserSignUp(formData)
          .then((value) => {
            if (value.status === "success") {
              getNormalUserData();
              toast.success(value.message);
              setTimeout(() => {
                setShow(!show);
                setShowEditmodal(false);
              }, 1000);
              resetForm();
            } else {
              toast.error(value.message);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      } catch (error) {
        console.error("API request error:", error);
        setErrorMessage("An error occurred during sign-up.");
      }
    },
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const rowsPerPage = 10;
  const startIndex = (normalPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  useEffect(() => {

  }, [userData]);
  const [formDataEdit, setFormDataEdit] = useState({
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    confirm_password: "",
    is_active: true,
    is_superuser: false,
  });
  const handleCLick = (id) => {
    setShowEditmodal(true);
    const matchingUser = userData.find((user) => user.id === id);
    if (matchingUser) {
      const phoneNumber =
        matchingUser.user_profile && matchingUser.user_profile[0]
          ? matchingUser.user_profile[0].phone_number
          : "";
      setFormDataEdit({
        id: matchingUser.id,
        email: matchingUser.email,
        first_name: matchingUser.first_name,
        last_name: matchingUser.last_name,
        phone_number: phoneNumber,
        is_active: matchingUser.is_active,
        is_superuser: matchingUser.is_superuser,
      });
    } else {
      console.log("No matching user found");
    }
  };
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const sessionUser = useSelector((state) => state.session.user);
  return (
    <>
      <Helmet>
        <title>Letswebinar | users</title>
      </Helmet>
      <Grid style={{ padding: "0" }}>
        <Layout showSideBar active={2}>
          <div className="nprmalusercontainer">
            <Grid className="gridPadding">
              <div className="heading-chatreport   noramuserHeading">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    textAlign: "start",
                    fontSize: "22",
                    fontWeight: "800",
                  }}
                >
                  {searchVisible ? (
                    <p> Normal Users List ({totalDataSearch})</p>
                  ) : (
                    <p> Normal Users List ({totalData})</p>
                  )}
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <div className="searchUser">
                      <TextField
                        id="outlined-basic"
                        placeholder="Search..."
                        variant="outlined"
                        value={searchName}
                        autoComplete="off"
                        onChange={handleSearch}
                        sx={{ "& input": { height: "7px" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IoSearchSharp style={{ fontSize: "22px" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="btn-prev createUserBtn"
                    sx={{ backgroundColor: colors.primary, fontWeight: "800" }}
                    onClick={() => {
                      setFormData({
                        first_name: "",
                        last_name: "",
                        edit: "add",
                      });

                      setShow(!show);
                    }}
                  >
                    Create Normal User
                  </Button>
                </div>
              </div>
              <TableContainer
                className="tablecontainer"
                sx={{ border: "none" }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="table-cell tableWebinar "
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        S.no
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Mobile No.
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Date and Time
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Verified User
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Blocked User
                      </TableCell> 
                       <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                       User Id
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <TableRow style={{ width: "100%" }}>
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            borderBottom: "none!important",
                          }}
                        >
                          <CircularProgress
                            style={{
                              position: "absolute",
                              left: "50%",
                              marginTop: "4rem",
                              display: "grid",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : userData?.length > 0 ? (
                      userData
                        .filter((row) => {
                          const fullName = `${row.first_name} ${row.last_name}`;
                          const email = row.email;
                          const phoneNumber = row.user_profile[0].phone_number;
                          console.log("rowrowrow", row);
                          return (
                            fullName
                              .toLowerCase()
                              .includes(searchName.toLowerCase()) ||
                            email
                              .toLowerCase()
                              .includes(searchName.toLowerCase()) ||
                            phoneNumber.includes(searchName)
                          );
                        })
                        .map(
                          (row, i) =>
                            btnVisible && (
                              <TableRow key={`${i}userData`}>
                                <TableCell
                                  className="table-celltr"
                                  component="th"
                                  scope="row"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  {startIndex + i + 1}
                                </TableCell>
                                <TableCell
                                  className="table-celltr"
                                  component="th"
                                  scope="row"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  {row.first_name + " " + row.last_name}
                                </TableCell>
                                <TableCell
                                  className="table-celltr"
                                  component="th"
                                  scope="row"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  {row.email}
                                </TableCell>
                                <TableCell
                                  className="table-celltr"
                                  component="th"
                                  scope="row"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  {row.user_profile[0].phone_number}
                                </TableCell>
                                <TableCell
                                  className="table-celltr"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  {formatDate(row.date_joined)}
                                </TableCell>

                                <TableCell
                                  className="table-celltr"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  <div>
                                    {row.is_superuser ? (
                                      <p>Active</p>
                                    ) : (
                                      <p>Inactive</p>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="table-celltr"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  <div>
                                    {row.is_active ? (
                                      <p>Unblocked</p>
                                    ) : (
                                      <p>Blocked</p>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="table-celltr"
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                   {row.id}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border:
                                      "1.94px solid rgb(117 114 116 / 31%) !important;",
                                  }}
                                >
                                  <Edit onClick={(id) => handleCLick(row.id)} />
                                </TableCell>
                              </TableRow>
                            )
                        )
                    ) : (
                      <TableRow style={{ width: "100%" }}>
                        <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                          <p>No matching data found</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack
                spacing={2}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showPage && (
                  <Pagination
                    count={totalRows}
                    page={normalPage}
                    onChange={handleUserPagination}
                    variant="outlined"
                    shape="rounded"
                    sx={{ color: "#6b59cc" }}
                  />
                )}
                {showPageSea && totalRows && (
                  <Pagination
                    count={totalRows}
                    page={normalPage}
                    onChange={handleSearchPagination}
                    variant="outlined"
                    shape="rounded"
                    sx={{ color: "#6b59cc" }}
                  />
                )}
              </Stack>
            </Grid>
            <Modal
              open={show}
              onClose={() => {
                setShow(!show);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  maxHeight: "80vh",
                  overflowY: "auto", 
                }}
                className="container-addQue ModalUser modal-normalUser"
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    textAlign: "center",
                    fontSize: "22",
                    fontWeight: "800",
                  }}
                >
                  Create Normal User
                </Typography>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={3}>
                    <div className="text-field">
                      <TextField
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        id="standard-textarea"
                        label="Email"
                        placeholder="Enter Email"
                        variant="standard"
                        type="email"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <div className="text-field w-textField m-20">
                      <TextField
                        error={
                          !!(
                            formik.touched.first_name &&
                            formik.errors.first_name
                          )
                        }
                        fullWidth
                        helperText={
                          formik.touched.first_name && formik.errors.first_name
                        }
                        id="standard-textarea"
                        label="First Name"
                        placeholder=" Enter First Name"
                        variant="standard"
                        type="text"
                        name="first_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="text-field w-textField">
                      <TextField
                        error={
                          !!(
                            formik.touched.last_name && formik.errors.last_name
                          )
                        }
                        fullWidth
                        helperText={
                          formik.touched.last_name && formik.errors.last_name
                        }
                        id="standard-textarea"
                        label="Last Name"
                        placeholder=" Enter Last Name"
                        variant="standard"
                        type="text"
                        name="last_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Stack>

                  <div className="text-field  ">
                    <TextField
                      id="standard-basic"
                      label="Phone no"
                      variant="standard"
                      type="tel"
                      error={
                        !!(
                          formik.touched.phone_number &&
                          formik.errors.phone_number
                        )
                      }
                      fullWidth
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                      placeholder=" Enter Phone Number"
                      name="phone_number"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phone_number}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PhoneAndroidIcon />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </div>

                  <div className="text-field  m-20">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      name="password"
                      label="Password"
                      placeholder="Enter Password"
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityIcon
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="text-field m-20">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      name="passwordConfirm"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.passwordConfirm}
                      error={
                        !!(
                          formik.touched.passwordConfirm &&
                          formik.errors.passwordConfirm
                        )
                      }
                      fullWidth
                      helperText={
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm
                      }
                      type={confirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {confirmPassword ? (
                              <VisibilityIcon
                                onClick={() =>
                                  setConfirmPassword(!confirmPassword)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() =>
                                  setConfirmPassword(!confirmPassword)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3 }} variant="body2">
                      {formik.errors.submit}
                    </Typography>
                  )}
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      className="agree-statement mt-12 conditonTitle"
                      style={{ marginTop: "11px", marginLeft: "0px" }}
                    >
                      Active User
                    </p>
                    <div>
                      <Switch
                        checked={formik.is_superuser}
                        defaultChecked
                        onChange={() => handleSwitchToggle("daily")}
                        color="primary"
                      />
                    </div>
                  </Stack>
                  <div className="bg-btncolor">
                    {btnVisible && (
                      <Button
                        fullWidth
                        size="large"
                        sx={{
                          mt: 3,
                        }}
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          addUsers();
                        }}
                      >
                        Create Normal User
                      </Button>
                    )}
                  </div>
                </form>
              </Box>
            </Modal>

            <Modal
              open={showeditmodal}
              onClose={() => {
                setShowEditmodal(!showeditmodal);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  maxHeight: "80vh", 
                  overflowY: "auto", 
                }}
                className="container-addQue"
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    textAlign: "center",
                    fontSize: "22",
                    fontWeight: "800",
                  }}
                >
                  Update Normal User
                </Typography>
                <div>
                  <TextField
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    fullWidth
                    value={formDataEdit.email}
                    onChange={(e) =>
                      handleInputChangeUpdate("email", e.target.value)
                    }
                    style={{ marginBottom: "12px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ marginBottom: "12px" }}
                    className="m-17"
                  >
                    <TextField
                      id="standard-basic"
                      label="First Name"
                      variant="standard"
                      value={formDataEdit.first_name}
                      onChange={(e) =>
                        handleInputChangeUpdate("first_name", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Last Name"
                      variant="standard"
                      value={formDataEdit.last_name}
                      onChange={(e) =>
                        handleInputChangeUpdate("last_name", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    value={formDataEdit.phone_number}
                    onChange={(e) =>
                      handleInputChangeUpdate("phone_number", e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneAndroidIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={3}
                  >
                    <p
                      className="agree-statement mt-12 conditonTitle"
                      style={{ marginTop: "11px", marginLeft: "0px" }}
                    >
                      Verified User
                    </p>

                    <div>
                      <Switch
                        checked={formDataEdit.is_superuser}
                        onChange={(event) => {
                          const newValue = event.target.checked;
                          console.log(
                            "Before update:",
                            formDataEdit.is_superuser
                          );
                          setFormDataEdit((prevData) => ({
                            ...prevData,
                            is_superuser: newValue,
                          }));
                          console.log(
                            "After update:",
                            formDataEdit.is_superuser
                          );
                        }}
                      />
                    </div>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={3}
                  >
                    <p
                      className="agree-statement mt-12 conditonTitle"
                      style={{ marginTop: "11px", marginLeft: "0px" }}
                    >
                      Blocked User
                    </p>
                    <div>
                      <Switch
                        checked={!formDataEdit.is_active}
                        onChange={(event) => {
                          console.log("Switch value changed");
                          const isChecked = event.target.checked;

                          if (isChecked && !formDataEdit.is_active) {
                            console.log("Switch is blocked");
                            return;
                          }

                          console.log("Updating state");
                          setFormDataEdit({
                            ...formDataEdit,
                            is_active: !isChecked,
                          });
                        }}
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <div
                    className="bg-btncolor"
                    style={{ color: "white", width: "100%" }}
                  >
                    {btnVisible && (
                      <Button
                        onClick={(id) => updateUser(formDataEdit.id)}
                        style={{
                          color: "white",
                          width: "100%",
                          marginTop: "2rem",
                        }}
                      >
                        Update Normal User
                      </Button>
                    )}
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </Layout>
      </Grid>
    </>
  );
}
