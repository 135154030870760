import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { sendEmailRecoverPassword } from "../../utils/apis/apis";
import { toast } from "react-toastify";
import images from "../../utils/constant/images";
import { Helmet } from "react-helmet";
const RecoverPassword = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .test("valid-email", "Invalid email address", function (value) {
          return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i.test(
            value
          );
        })
        .required("You must enter a email"),
    }),
    onSubmit: async (values, helpers) => {
      console.log("issssssssssss", values);
      sendEmail(values.email);
    },
  });
  const sendEmail = async (email) => {
    await sendEmailRecoverPassword({ email: email })
      .then((val) => {
        if (val.status == "success") {
          toast.success(val.message);
        } else {
          toast.error(val.message);
        }
      })
      .catch((err) => {
        console.error("Upload error:", err);
      });
  };
  return (
    <>
      <Helmet>
        <title>Letswebinar | recoverPassword</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0rem 0rem 0rem",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: "100px",
          }}
          className="box-form"
        >
          <Box
            sx={{
              textAlign: "center",
              margin: "20px 0px",
            }}
          >
            <img
              alt="Forget Password"
              src={images.spiral}
              className="img-forgetPassword"
            />
          </Box>
          <div>
            <Stack spacing={1} sx={{ mb: 3 }} className="card-stack-heading">
              <Typography
                variant="h4"
                className="heading-form"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Lost your password?
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                className="sub-heading-form"
                style={{ textAlign: "center" }}
              >
                Enter your details to proceed further
              </Typography>
              <Typography className="successMessage">
                {successMessage}
              </Typography>
              <Typography className="errorMessage">{errorMessage}</Typography>
            </Stack>

            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3} sx={{ width: "100%" }}>
                <div className="text-field" sx={{ width: "100%" }}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullwidth
                    helperText={formik.touched.email && formik.errors.email}
                    id="standard-textarea"
                    label="Email"
                    placeholder="Enter Email"
                    variant="standard"
                    type="email"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: "100%" }}
                  />
                </div>
              </Stack>

              <div className="bg-btncolor">
                <Button
                  fullwidth
                  size="large"
                  sx={{
                    width: "100%",
                    margin: "37px 0px 0px 0px",
                    backgroundColor: "var(--themecolor)",
                  }}
                  type="submit"
                  variant="contained"
                >
                  Recover
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default RecoverPassword;
