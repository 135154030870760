import React, { useEffect, useState } from "react";
import Layout from "../common/layout";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@mui/base";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import ChatMassage from "./ChatMassage";
import {
  Box,
  Button,
  CircularProgress,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Participants from "./Participents";
import useWebSocket, { ReadyState } from "react-use-websocket";
import appConfig from "../../utils/constant/appConfig";
import { chatReport, getVideoHlsPath } from "../../utils/apis/apis";
import ErrorPage, { StartWebinar } from "./../pageNotFound/index";
import * as sessionActions from "../../store/session";
import { Helmet } from "react-helmet";
let durationTime = 0;
export default function WebinarView() {
  const sessionUser = useSelector((state) => state.session.user);
  const navigate = useNavigate();
  const { webinarId } = useParams();
  const [value, setValue] = React.useState(0);
  const [ListData, setListdata] = useState([]);
  const [ActiveData, setActiveListdata] = useState([]);
  const [InactiveData, setInactiveListdata] = useState([]);
  const [startDummyUserChat, setStartDummyUserChat] = useState(false);
  const [hlsUrl, setHlsUrl] = useState("");
  const [liveWebinarInfo, setLiveWebinarInfo] = useState({});
  const [liveWebinarIsActive, setLiveWebinarIsActive] = useState(false);
  const filterActiveList = ListData.filter((list) => {
    return list.status === "active";
  });
  const filterInactiveList = ListData.filter((list) => {
    return list.status === "Inactive";
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [webinarParticepents, setWebinarParticepents] = useState([]);
  const [webinarChats, setWebinarChats] = useState([]);
  const [webinarAllChats, setWebinarAllChats] = useState([]);
  const [durations, setDurations] = useState("");
  const [loading, setLoading] = useState(true);
  const [getTitle,setGetTtitle] = useState('');
  const [getDesc,setGetDesc] = useState('');
  const participantsLabel = `Participants (${webinarParticepents.length})`;
  const chatsLabel = `Chats (${webinarChats.length})`;
  const [isAdminUser, setIsAdminUser] = React.useState(" ");
  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useDispatch();
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const location = useLocation();
  const socketUrl = `${appConfig.WEB_SOCKET_URL}/${sessionUser?.id}/${webinarId}/`;
  useEffect(() => {
    if (sessionUser?.id && webinarId) {
      const socketUrl = `${appConfig.WEB_SOCKET_URL}/${sessionUser.id}/${webinarId}/`;
    }
  }, [sessionUser, webinarId]);
  const socketOptions = {
    shouldReconnect: () => true,
  };
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl,
    socketOptions
  );
  useEffect(() => {
    var msgList = [];
    if (lastMessage) {
      const lastData = JSON.parse(lastMessage.data);
      if (lastData.participants.length > 0) {
        setWebinarParticepents(lastData.participants);
      }
      if (lastData.chatmsg) {
        setWebinarChats((prevMessages) => [...prevMessages, lastData.chatmsg]);
      }
    }
  }, [lastMessage]);

  const sendTxtMsg = (val) => {
    const inputData = val.replace(/\n/g, " ").replace(/\s+/g, " ").trim();
    sendMessage(
      `{"token":"","text":"${inputData}","type":"chat_msg","sessionTime":"${durationTime}","totalDuration":"${durations}"}`
    );
  };
  useEffect(() => {
    setTimeout(() => {
      sendMessage(
        `{"token":"","text":"${"i am join"}","type":"user_join","sessionTime":"0.750061","totalDuration":"${durations}"}`
      );
    }, 3000);
  }, []);

  useEffect(() => {
    videoURLFile();
  }, []);
  const videoURLFile = async () => {
    await getVideoHlsPath(webinarId)
      .then((val) => {
        if (val.reschedule == true) {
          videoURLFile();
        } else {
          setHlsUrl(val.video_url);
          setGetTtitle(val.name)
          setGetDesc(val.title)
          setLiveWebinarInfo(val);
          setLiveWebinarIsActive(val.active);
          setLoading(false);
          if (val.is_schedule == true) {
            allChatMsg();
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const allChatMsg = async () => {
    await chatReport(sessionUser.access, webinarId)
      .then((val) => {
        let jsonData = val.data;
        jsonData.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        setWebinarChats((prevMessages) => [...prevMessages, ...jsonData]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (userInfo) {
      const localStorageUserId = userInfo.id;
      const webinarChatsUserId = webinarChats[0]?.userId;

      if (localStorageUserId === webinarChatsUserId) {
      } else {
      }

      setIsAdminUser(localStorageUserId === webinarChatsUserId);
    } else {
    }
  }, [dispatch, webinarChats]);
  const handleClick = () => {
    setIsClicked(true); 
  };
  return (
    <>
      {hlsUrl && loading == false && liveWebinarIsActive == true ? (
         <Layout active={1} isLeave>
        <Helmet>
          <title>Letswebinar | {getTitle}</title>
        </Helmet>
          <>
            <div className="ssss" onClick={handleClick}>
              <Grid
                container
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  background: "black",
                }}
              >
                <Grid
                  item
                  xs={12}
                  lg={9}
                  md={9}
                  style={{
                    position: "static",
                    top: "10%",
                    left: "0",
                    overflow: "hidden",
                    height: "100%",
                  }}
                >
                  <div className="container-webinarview">
                    <VideoPlayer
                      style={{ maxHeight: "933px", overflow: "hidden" }}
                      videoUrl={hlsUrl}
                      startVideoDuration={
                        liveWebinarInfo?.webinar_video_duration
                      }
                      getSecondValue={(val) => {
                        durationTime = val;
                        sendMessage(
                          `{"token":"09AHfSPUdJAkTz8DxX1ZAAXvGt2w8IO7sCkRnJCOo","text":"${"hello"}","type":"chat_msg","sessionTime":"${val}","totalDuration":"${durations}"}`
                        );
                      }}
                      totalDuration={(val) => {
                        setDurations(val);
                        sendMessage(
                          `{"token":"09AHfSPUdJAkTz8DxX1ZAAXvGt2w8IO7sCkRnJCOo","text":"${"hello"}","type":"chat_msg","sessionTime":"0.750061","totalDuration":"${val}"}`
                        );
                      }}
                      isClicked={isClicked}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div>
                    <Box
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                        backgroundColor: "#f5f3f0",
                      }}
                    >
                      <>
                        <Tabs
                          value={currentTabIndex}
                          onChange={handleTabChange}
                          style={{ borderBottom: "2px solid #554d4d94" }}
                        >
                          <Tab
                            label={participantsLabel}
                            className="participant-tab"
                          />
                          <Tab label={chatsLabel} className="m-chats" />
                        </Tabs>

                        {currentTabIndex === 0 && (
                          <Box className='p-participant'>
                            <Participants
                              style={{ height: " calc(100% - 50px)" }}
                              webinarParticepents={webinarParticepents}
                            />
                          </Box>
                        )}

                        {currentTabIndex === 1 && (
                          <Box>
                            <ChatMassage
                              className="msger"
                              sx={{ padding: "12px 0px" }}
                              webinarChats={webinarChats}
                              webinarId={webinarId}
                              isAdminUser={isAdminUser}
                              send={(val) => {
                                sendTxtMsg(val);
                              }}
                            />
                          </Box>
                        )}
                      </>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        </Layout>
      ) : loading && hlsUrl == "" ? (
        <CircularProgress className="cicular_loader"/>
      ) : (
        <>
          {liveWebinarIsActive == false ? (
            <StartWebinar
              getSeconds={() => {
                setLiveWebinarIsActive(true);
              }}
              startTime={liveWebinarInfo?.duration_time}
            />
          ) : (
            <ErrorPage />
          )}
        </>
      )}
    </>
  );
}
