import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DetailsIcon from "@mui/icons-material/Details";
import VideocamIcon from "@mui/icons-material/Videocam";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import MoreIcon from "@mui/icons-material/More";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import TitleIcon from "@mui/icons-material/Title";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const EventsOthers = () => {
  const [value, setValue] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [fields, setFields] = React.useState({
    name: "",
    title: "",
  });
  const [fieldErrors, setFieldErrors] = React.useState({
    name: "",
    title: "",
  });
  const [selectedOptionError, setSelectedOptionError] = React.useState("");
  const [switches, setSwitches] = React.useState({
    questionToggle: false,
    liveChatToggle: false,
    twoDayRemainderEmails: false,
    oneDayRemainderEmails: false,
    threeHoursRemainderEmails: false,
    togleconfirmation: false,
    togleOneDayconfirmation: false,
    togleMintuesconfirmation: false,
  });
  const [open, setOpen] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [selectedMinute, setSelectedMinute] = React.useState(0);
  const [selectedSecond, setSelectedSecond] = React.useState(0);
  const [selectedHour, setSelectedHour] = React.useState(0);
  const handleSwitchToggle = (switchName) => {
    console.log(switchName);
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [switchName]: !prevSwitches[switchName],
    }));
  };
  return (
    <>
      <Box className="container-live">
        <div className="heading-events">
          <Typography variant="h4" gutterBottom style={{ textAlign: "start" }}>
            Webinar Email Notification
          </Typography>{" "}
        </div>
        <Box sx={{ flexGrow: 1, margin: "12px 0px 0px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={7} md={7} lg={7}>
              <div className="button-container">
                <Typography className="heading-toggletxt">
                  1 Day Remainder Emails
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!switches.oneDayRemainderEmails}
                  style={{
                    backgroundColor: switches.oneDayRemainderEmails
                      ? "#8600d3"
                      : "rgba(0, 0, 0, 0.12)",
                    fontWeight: "700",
                    fontSize: "13px",
                    borderRadius: "9px",
                  }}
                >
                  {switches.oneDayRemainderEmails ? "Enabled" : "Disabled"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <div style={{ textAlign: "right" }}>
                <Switch
                  checked={switches.oneDayRemainderEmails}
                  onChange={() => handleSwitchToggle("oneDayRemainderEmails")}
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, margin: "12px 0px 0px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={7} md={7} lg={7}>
              <div className="button-container">
                <Typography className="heading-toggletxt">
                  2 Day Remainder Emails
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!switches.twoDayRemainderEmails}
                  style={{
                    backgroundColor: switches.twoDayRemainderEmails
                      ? "#8600d3"
                      : "rgba(0, 0, 0, 0.12)",
                    fontWeight: "700",
                    fontSize: "13px",
                    borderRadius: "9px",
                  }}
                >
                  {switches.twoDayRemainderEmails ? "Enabled" : "Disabled"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <div style={{ textAlign: "right" }}>
                <Switch
                  checked={switches.twoDayRemainderEmails}
                  onChange={() => handleSwitchToggle("twoDayRemainderEmails")}
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, margin: "12px 0px 1rem 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={7} md={7} lg={7}>
              <div className="button-container">
                <Typography className="heading-toggletxt">
                  3 Hours Remainder Emails
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!switches.threeHoursRemainderEmails}
                  style={{
                    backgroundColor: switches.threeHoursRemainderEmails
                      ? "#8600d3"
                      : "rgba(0, 0, 0, 0.12)",
                    fontWeight: "700",
                    fontSize: "13px",
                    borderRadius: "9px",
                  }}
                >
                  {switches.threeHoursRemainderEmails ? "Enabled" : "Disabled"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <div style={{ textAlign: "right" }}>
                <Switch
                  checked={switches.threeHoursRemainderEmails}
                  onChange={() =>
                    handleSwitchToggle("threeHoursRemainderEmails")
                  }
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, margin: "1rem 0px 0px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={7} md={7} lg={7}>
              <div className="button-container">
                <Typography className="heading-toggletxt">
                  Toggle Confirmation text Message
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!switches.togleconfirmation}
                  style={{
                    backgroundColor: switches.togleconfirmation
                      ? "#8600d3"
                      : "rgba(0, 0, 0, 0.12)",
                    fontWeight: "700",
                    fontSize: "13px",
                    borderRadius: "9px",
                  }}
                >
                  {switches.togleconfirmation ? "Enabled" : "Disabled"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <div style={{ textAlign: "right" }}>
                <Switch
                  checked={switches.togleconfirmation}
                  onChange={() => handleSwitchToggle("togleconfirmation")}
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, margin: "12px 0px 0px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={7} md={7} lg={7}>
              <div className="button-container">
                <Typography className="heading-toggletxt">
                  Toggle 1 Day Confirmation text Message
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!switches.togleOneDayconfirmation}
                  style={{
                    backgroundColor: switches.togleOneDayconfirmation
                      ? "#8600d3"
                      : "rgba(0, 0, 0, 0.12)",
                    fontWeight: "700",
                    fontSize: "13px",
                    borderRadius: "9px",
                  }}
                >
                  {switches.togleOneDayconfirmation ? "Enabled" : "Disabled"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <div style={{ textAlign: "right" }}>
                <Switch
                  checked={switches.togleOneDayconfirmation}
                  onChange={() => handleSwitchToggle("togleOneDayconfirmation")}
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, margin: "12px 0px 0px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={7} md={7} lg={7}>
              <div className="button-container">
                <Typography className="heading-toggletxt">
                  Toggle 30 Mintues Confirmation text Message
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!switches.togleMintuesconfirmation}
                  style={{
                    backgroundColor: switches.togleMintuesconfirmation
                      ? "#8600d3"
                      : "rgba(0, 0, 0, 0.12)",
                    fontWeight: "700",
                    fontSize: "13px",
                    borderRadius: "9px",
                  }}
                >
                  {switches.togleMintuesconfirmation ? "Enabled" : "Disabled"}
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <div style={{ textAlign: "right" }}>
                <Switch
                  checked={switches.togleMintuesconfirmation}
                  onChange={() =>
                    handleSwitchToggle("togleMintuesconfirmation")
                  }
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EventsOthers;
