import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Pagination,
  Card,
  CardContent,
  IconButton,
  Menu,
} from "@mui/material";
import { IoSearchSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import axios from "axios";
import {
  getFilterData,
  getSearchData,
  webAnalyticsSession,
  webRegistrantsExcel,
} from "../../utils/apis/apis";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx"; // Excel library
import { FaDownload } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import "../webinarAnalytics/style.css";
const headerData = [
  {
    title: "No.of series",
  },
  {
    title: "E-mail",
  },
  {
    title: "Full Name",
  },
  { title: "Phone Number" },
  {
    title: "Attended webinar",
  },
  {
    title: "Registration Date",
  },
  {
    title: "Total Percentage",
  },
  {
    title: "Tags",
  },
];
export default function RegistrantsTables({
  data = [],
  fun,
  searchVal,
  rowsPerPage,
  handleChangeFilter,
  valueFilter,
  handleRegpagination,
  regPages,
  regPages1,
  regPages2,
  totalRows,
  showPage,
  showPageSea,
  showPage1,
  showPageSea1,
  handleSearchPagination,
  handleSearch,
  resetTable,
  totalRows1,
  showPageFilter1,
  handleFilterPagination,
  showPageFilter,
  setSearchText,
  searchText,
  totalData,
  totalDataSearch,
  totalRowsSearch,
  totalDataFilter,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const { webinarId } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  const [attendValue, setAttendValue] = useState("");
  const [show, setShow] = useState(false);
  const handleOPn = () => {
    setShow(true);
  };
  const [firstSelect, setFirstSelect] = useState("");
  const [secondSelect, setSecondSelect] = useState("");
  const [selectedPercentage, setSelectedPercentage] = useState("");
  const [tags, setTag] = useState("");
  const firstSelectOptions = ["Option 1", "Option 2", "Option 3"];
  const [showCard, setShowCard] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const handleFirstSelectChange = (event) => {
    const selectedValue = event.target?.value;
    setFirstSelect(selectedValue);
    setSecondSelect("");
  };
  const exportToExcel = async (nextPageToFetch) => {
    try {
      const response = await webRegistrantsExcel(
        sessionUser.access,
        webinarId,
        nextPageToFetch
      );
      const data = response.all_registration_data;

      const formattedData = data.map((item, index) => ({
        "No.of series": index + 1,
        "E-mail": item.email,
        "Full Name": item.full_name,
        "Phone Number": item.phone_number,
        "Attended webinar": item.attend_webinar,
        "Registration Date": item.registration_date,
        "Total Percentage": item.percentage,
        Tag: item.tag,
      }));
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Registrants");
      XLSX.writeFile(workbook, "registrants.xlsx", {
        bookType: "xlsx",
        mimeType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  const cardRef = useRef(null);
  const handleClickMore = () => {
    setShowCard(!showCard);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid>
        <Grid className="session-container">
          <Stack className="registrants-align" style={{ width: "90vw" }}>
            <div
              className="heading-registrant"
              style={{ fontWeight: " 500!important" }}
            >
              <Typography
                variant="h4"
                gutterBottom
                style={{ textAlign: "start", fontWeight: " 500!important" }}
              >
                {showPage1 && (
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: "start", fontWeight: "500" }}
                  >
                    Registrants ({totalData})
                  </Typography>
                )}

                {showPageSea1 && (
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: "start", fontWeight: "500" }}
                  >
                    Registrants ({totalDataSearch})
                  </Typography>
                )}
                {showPageFilter1 && (
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: "start", fontWeight: "500" }}
                  >
                    Registrants ({totalDataFilter})
                  </Typography>
                )}
              </Typography>
            </div>
            <div className="searchReagistrant" style={{}}>
              <TextField
                id="outlined-basic"
                placeholder="Search..."
                variant="outlined"
                value={searchText}
                type="text"
                sx={{ "& input": { height: "7px" } }}
                onChange={(e) => {
                  setSearchText(e.target?.value);
                  fun(e.target?.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoSearchSharp style={{ fontSize: "22px" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <IoMdMore className="showMoreBtn" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControl sx={{ m: 1, minWidth: 223 }}>
                        <Select
                          value={valueFilter}
                          onChange={(e) => handleChangeFilter(e, regPages2)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{ height: "40px", width: "100%" }}
                        >
                          <MenuItem value={""}>
                            <em>Select Percentage</em>
                          </MenuItem>

                          <MenuItem value={"0-25"}>0%- 25%</MenuItem>
                          <MenuItem value={"25-50"}>25%-50%</MenuItem>
                          <MenuItem value={"50-75"}>50%-75%</MenuItem>
                          <MenuItem value={"75-100"}>75%-100%</MenuItem>
                        </Select>
                      </FormControl>
                      <div
                        className="downlodsheet"
                        style={{ alignContent: "center" }}
                      >
                        Dowload Registrants
                        <FaDownload
                          onClick={exportToExcel}
                          className="downloadExc"
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Menu>
              </div>

              <FormControl sx={{ m: 1, minWidth: 120 }} className="formSelect">
                <Select
                  value={valueFilter}
                  onChange={(e) => handleChangeFilter(e, regPages2)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "40px", width: "100px" }}
                >
                  <MenuItem value={""}>
                    <em>Select Percentage</em>
                  </MenuItem>
                  <MenuItem value={"0-25"}>0%-25%</MenuItem>
                  <MenuItem value={"25-50"}>25%-50%</MenuItem>
                  <MenuItem value={"50-75"}>50%-75%</MenuItem>
                  <MenuItem value={"75-100"}>75%-100%</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  textAlign: "end",
                  marginTop: "-22px",
                  marginRight: "17px",
                }}
                className="btn-Addque"
              >
                <Button variant="contained" onClick={resetTable}>
                  Reset Registrants
                </Button>
              </div>
              <FaDownload
                onClick={exportToExcel}
                className="downloadExc formSelect"
              />
            </div>
          </Stack>
          <div
            className="heading-registrant"
            style={{ fontWeight: " 500!important" }}
          ></div>
          <TableContainer sx={{ width: "90vw", top: "500px" }} className="">
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {headerData.map((item, i) => (
                    <TableCell
                      key={`headerData${i}`}
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      {item.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) && data.length > 0 ? (
                  data
                    .filter((row) => {
                      if (selectedPercentage) {
                        const [min, max] = selectedPercentage
                          .split("-")
                          .map(Number);
                        const percentage = parseInt(row.percentage, 10);

                        return percentage >= min && percentage <= max;
                      }
                      return true;
                    })
                    ?.slice(startIndex, endIndex)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {showPage && (regPages - 1) * rowsPerPage + index + 1}
                          {showPageSea &&
                            (regPages1 - 1) * rowsPerPage + index + 1}
                          {showPageFilter &&
                            (regPages2 - 1) * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {row.full_name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {row.phone_number}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {row.attend_webinar}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {row.registration_date}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {row.percentage}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            fontSize: "15px",
                            border:
                              "1.94px solid rgb(117 114 116 / 31%) !important;",
                          }}
                        >
                          {[
                            row.tags_data && row.tags_data.tag,
                            row.tags_data && row.tags_data.tag_user,
                            row.tags_data && row.tags_data.tag_company,
                            row.tags_data && row.tags_data.tag_custom,
                            row.tags_data && row.tags_data.tag_custom2,
                            row.tags_data && row.tags_data.tag_source,
                            row.tags_data && row.tags_data.tag_medium,
                            row.tags_data && row.tags_data.tag_term,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headerData.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction={"row"} justifyContent={"center"} mt={4}>
            {showPage && (
              <Pagination
                count={totalRows}
                page={regPages}
                onChange={handleRegpagination}
                variant="outlined"
                shape="rounded"
              />
            )}
            {showPageSea && (
              <Pagination
                count={totalRowsSearch}
                page={regPages1}
                onChange={handleSearchPagination}
                variant="outlined"
                shape="rounded"
              />
            )}
            {showPageFilter && (
              <Pagination
                count={totalRows1}
                page={regPages2}
                onChange={handleFilterPagination}
                variant="outlined"
                shape="rounded"
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
