import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
import appConfig from "../../utils/constant/appConfig";
import { guestUserAttendees } from "../../utils/apis/apis";
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../store/session";
import { toast } from "react-toastify";

const VideoPlayer = ({
  videoUrl,
  getSecondValue,
  totalDuration,
  startVideoDuration,
}) => {
  const navigate = useNavigate();
  const { webinarId } = useParams();
  const dispatch = useDispatch();
  const [soundOn, setSoundOn] = useState(false);
  const [showLoader, setLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const sessionUser = useSelector((state) => state.session.user);
  const playerRef = useRef();
  const queryParams = new URLSearchParams(window.location.search);
  const tagsParam = queryParams.get("tag");
  const tagsUserParam = queryParams.get("tag_user");
  const tagsCompanyParam = queryParams.get("tag_company");
  const tagsCustomParam = queryParams.get("tag_custom");
  const tagsCustom2Param = queryParams.get("tag_custom2");
  const tagsSourceParam = queryParams.get("tag_source");
  const tagsMediumParam = queryParams.get("tag_medium");
  const tagsCampaignParam = queryParams.get("tag_campaign");
  const tagsContentParam = queryParams.get("tag_content");
  const tagsTermsParam = queryParams.get("tag_term");
  const [selectedBitrateIndex, setSelectedBitrateIndex] = useState(0); 
  const handleToggleMute = () => {
    setSoundOn((prevSoundOn) => !prevSoundOn);
  };

  const handleVideoClick = () => {
    handleToggleMute();
  };

  const handleBuffer = () => {
    setLoader(true);
  };

  const handleBufferEnd = () => {
    setLoader(false);
  };

  const onReady = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(startVideoDuration, "seconds");
      const internalPlayer = playerRef.current.getInternalPlayer();
      if (soundOn) {
        internalPlayer.setAttribute("disablePictureInPicture", "true");
      } else {
        internalPlayer.removeAttribute("disablePictureInPicture");
      }
    }
  }, [playerRef.current, soundOn, startVideoDuration]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const loginWithExistingUrl = async (formData) => {
    try {
      const value = await guestUserAttendees(formData);
      const userAllData = value.data;
      if (value.status === "success") {
        if (!sessionUser.refresh) {
          localStorage.setItem("user", JSON.stringify(userAllData));
          dispatch(sessionActions.setUser(userAllData));
        } else {
          return;
        }
        toast.success(value.message);
        setLoading(false);
        navigate(`/webinarView/${webinarId}`);
      } else {
        toast.error(value.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("API request error:", error);
      toast.error("Error occurred while submitting the form.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!sessionUser.refresh) {
      const tagsDict = {
        tag: tagsParam || "",
        tag_user: tagsUserParam?.toString() || "",
        tag_company: tagsCompanyParam?.toString() || "",
        tag_custom: tagsCustomParam?.toString() || "",
        tag_custom2: tagsCustom2Param?.toString() || "",
        tag_source: tagsSourceParam?.toString() || "",
        tag_medium: tagsMediumParam?.toString() || "",
        tag_campaign: tagsCampaignParam?.toString() || "",
        tag_content: tagsContentParam?.toString() || "",
        tag_term: tagsTermsParam?.toString() || "",
      };

      const formData = {
        webinar: webinarId,
        full_name: sessionUser.full_name,
        guest_email: sessionUser.guest_email,
        guest_phone: sessionUser.guest_phone,
        tags: tagsDict,
      };

      setTimeout(() => {
        loginWithExistingUrl(formData);
      }, 1000);
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        minHeight: "100%",
      }}
      className="video-div"
    >
      {showLoader && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        className="volume-icon"
        style={{
          backgroundColor: soundOn ? "" : "rgb(0 0 0 / 71%)",
          display: soundOn ? "none" : "block",
          padding: "0px 5px",
        }}
      >
        {soundOn ? (
          <IconButton sx={{ color: "white" }}>
            <div className="volume-txt">
              <Typography
                variant="h5"
                style={{ display: soundOn ? "none" : "block" }}
              >
                Webinar Start
              </Typography>
            </div>
          </IconButton>
        ) : (
          <IconButton sx={{ color: "#f5f5f5d4" }} onClick={handleToggleMute}>
            <div className="volume-txt">
              <Typography variant="h5">Join Webinar </Typography>
            </div>
          </IconButton>
        )}
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          pointerEvents: "auto",
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          playsinline
          width="100%"
          height="100%"
          playing={true}
          controls={false}
          volume={1}
          muted={!soundOn}
          onDisablePIP={true}
          style={{
            objectFit: "cover",
          }}
          onDuration={(val) => {
            setTimeout(() => {
              totalDuration(val);
            }, 5000);
          }}
          onProgress={(val) => {
            getSecondValue(val.playedSeconds);
          }}
          onReady={onReady}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          onEnded={() => {
            navigate(`${routerPath.webinarAttendSuccess}/${webinarId}`);
          }}
          disableContextMenu={true}
          config={{
            file: {
              attributes: {
                preload: "none",
              },
              hlsOptions: {
                startLevel: 0,
              },
              hlsVersion: '0.14.0',
            },
            setAttribute: {
              allowsInlineMediaPlayback: true,
            },
            youtube: {
              playerVars:false,
              embedOptions: false,
            },
            vimeo: {
              playerOptions: false,
              iframeParams: { fullscreen: 0 },
            },
          }}
          controlsList="nodownload"
          onClick={handleVideoClick}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
