import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);
const ErrorPage = () => {
  const sessionUser = useSelector((state) => state.session.user);
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          mt: 25,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" sx={{ mb: 3 }} variant="h3">
              404: The page you are looking for isn’t here
            </Typography>{" "}
            {sessionUser?.refresh ?

              <Button
                className="pnf-button btnerror"
                href={"/home"}
                sx={{
                  mt: 3,
                  p: 3,
                  backgroundColor: "#6b59cc",
                  color: "white",
                  fontWeight: "bold",
                }}
                variant="contained"

              >
                Go back to dashboard
              </Button> : null
            }
          </Box>
        </Container>
      </Box>
    </>
  );
}

export const StartWebinar = ({ startTime, getSeconds }) => {
  const mmm = Math.ceil(startTime);
  const [seconds, setSeconds] = useState(mmm);
  const sessionUser = useSelector((state) => state.session.user);
  const sessionUserTime = sessionUser.session_time;
  useEffect(() => {
    const millisecondsSinceEpoch = startTime * 24 * 60 * 60 * 1000;
    const date = new Date(millisecondsSinceEpoch);
    console.log("date", date);
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalId);
          return prevSeconds;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const days = Math.floor(seconds / (24 * 3600));
  const remainingSecondsWithoutDays = seconds % (24 * 3600);
  const hours = Math.floor(remainingSecondsWithoutDays / 3600);
  const remainingSecondsWithoutHours = remainingSecondsWithoutDays % 3600;
  const minutes = Math.floor(remainingSecondsWithoutHours / 60);
  const remainingSeconds = remainingSecondsWithoutHours % 60;
  useEffect(() => {
    if (seconds === 0) {
      console.log("seconds", seconds);
      setSeconds(0);
      getSeconds(false);
    }
  }, [seconds]);
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          mt: 25,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" sx={{ mb: 3 }} variant="h3">
            The webinar will start in:
            </Typography>{" "}
            <Typography align="center" sx={{ mb: 3 }} variant="h3">
              {days > 0
                ? `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`
                : `${hours}h ${minutes}m ${remainingSeconds}s`}
            </Typography>
            {!sessionUserTime?   <Button
              className="pnf-button"
              href="/home"
              sx={{
                mt: 3,
                p: 3,
                backgroundColor: "#6b59cc",
                color: "white",
                fontWeight: "bold",
              }}
              variant="contained"
            >
              Go back to dashboard
            </Button>:null} 
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ErrorPage;
