import { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputAdornment from "@mui/material/InputAdornment";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
import { forgetPasswordByEmail } from "../../utils/apis/apis";
import { toast } from "react-toastify";
import images from "../../utils/constant/images";
import { Helmet } from "react-helmet";
const ForgetPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { userId, userToken } = useParams();
  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("Please enter your new password.")
        .min(8, "Password is too short - should be 8 chars minimum."),
      confirm_password: Yup.string()
        .required("Please enter your Confirm password.")
        .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      confirmPasswords(values);
    },
  });
  const confirmPasswords = async (values) => {
    await forgetPasswordByEmail(userId, userToken, {
      password: values.new_password,
      confirm_password: values.confirm_password,
    })
      .then((val) => {
        if (val.status == "success") {
          toast.success(val.message);
          navigate(routerPath.home);
        } else {
          toast.error(val.message);
        }
      })
      .catch((err) => {
        toast.error("Token is not Valid or Expired");
      });
  };
  return (
    <>
      <Helmet>
        <title>Letswebinar | forgetPassword</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0rem 0rem 0rem",
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
            px: 3,
            py: "100px",
            width: "100%",
          }}
          className="box-form"
        >
          <img
            alt="Forget Password"
            src={images.spiral}
            className="img-forgetPassword"
          />
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography
                variant="h4"
                className="heading-form"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Lost your password?
              </Typography>
              <Typography
                variant="h4"
                className="heading-form"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Enter your details to recover.
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                className="sub-heading-form"
              >
                Enter your details to proceed further
              </Typography>
              <Typography className="errorMessage">{errorMessage}</Typography>
            </Stack>

            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <div className="text-field m-17">
                  <TextField
                    id="new_password"
                    label="New Password"
                    variant="standard"
                    placeholder="Enter New Password"
                    type="password"
                    error={
                      !!(
                        formik.touched.new_password &&
                        formik.errors.new_password
                      )
                    }
                    fullwidth
                    helperText={
                      formik.touched.new_password && formik.errors.new_password
                    }
                    name="new_password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.new_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="text-field m-17">
                  <TextField
                    id="confirm_password"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    variant="standard"
                    type="password"
                    error={
                      !!(
                        formik.touched.confirm_password &&
                        formik.errors.confirm_password
                      )
                    }
                    fullwidth
                    helperText={
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password
                    }
                    name="confirm_password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirm_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CheckCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Stack>

              <Button
                fullwidth
                size="large"
                sx={{
                  margin: "37px 0px 0px 0px ;",
                  backgroundColor: "var(--themecolor)",
                }}
                type="submit"
                variant="contained"
                className="bg-btncolor "
              >
                Change Password
              </Button>
            </form>
            <NavLink className="link" to={routerPath.recoverPassword}>
              <p className="recover-pass">Resend Email</p>
            </NavLink>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default ForgetPassword;
