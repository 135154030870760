import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Divider } from "@mui/material";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getGhostUsersList,
  handleAddQuestions,
  handleAddAnswer,
  deleteAnswerList,
  deleteQuestionsAnswerList,
  getQuesAndAnsList,
  getGhostSelectedList,
} from "../../utils/apis/apis";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
} from "@mui/material";
const generateNumbersArray = (start, end) => {
  const numbers = [];
  for (let i = start; i <= end; i++) {
    let label;
    if (i < 10) {
      label = `0${i}`;
    } else {
      label = i.toString();
    }
    numbers.push({ value: i, label });
  }
  return numbers;
};
const Hours = generateNumbersArray(0, 24);
const minutesList = (start, end) => {
  const miutesList = [];
  for (let i = start; i <= end; i++) {
    let label;
    if (i < 10) {
      label = `0${i}`;
    } else {
      label = i.toString();
    }
    miutesList.push({ value: i, label });
  }
  return miutesList;
};
const Minutes = minutesList(0, 59);
const secondsList = (start, end) => {
  const secondsNum = [];
  for (let i = start; i <= end; i++) {
    let label;
    if (i < 10) {
      label = `0${i}`;
    } else {
      label = i.toString();
    }
    secondsNum.push({ value: i, label });
  }
  return secondsNum;
};
const Seconds = secondsList(0, 59);
const generateAnsHours = (start, end) => {
  const HoursAnsNum = [];
  for (let i = start; i <= end; i++) {
    let label;
    if (i < 10) {
      label = `0${i}`;
    } else {
      label = i.toString();
    }
    HoursAnsNum.push({ value: i, label });
  }
  return HoursAnsNum;
};
const HoursAns = generateAnsHours(0, 24);
const generateAnsMinutes = (start, end) => {
  const MinuteAnsNum = [];
  for (let i = start; i <= end; i++) {
    let label;
    if (i < 10) {
      label = `0${i}`;
    } else {
      label = i.toString();
    }
    MinuteAnsNum.push({ value: i, label });
  }
  return MinuteAnsNum;
};
const MinutesAns = generateAnsMinutes(0, 59);
const generateAnsSeconds = (start, end) => {
  const SecondsAnsNum = [];
  for (let i = start; i <= end; i++) {
    let label;
    if (i < 10) {
      label = `0${i}`;
    } else {
      label = i.toString();
    }
    SecondsAnsNum.push({ value: i, label });
  }
  return SecondsAnsNum;
};
const SecondsAns = generateAnsSeconds(0, 59);
const style = {
  marginTop: "20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
const EventsQueAns = ({ webinarId, editWebi }) => {
  const sessionUser = useSelector((state) => state.session.user);
  const [ghostUsers, setGhostUsers] = useState([]);
  const [data, setData] = useState([]);
  const [showQuestion, SetShowQuestion] = useState();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openAns, setOpenAns] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseAnsModalModal = () => setOpen(false);
  const [displayText, setDisplayText] = useState(false);
  const [quesid, setQuesId] = useState(null);
  const handleOpenasn = () => {
    setDisplayText(!displayText);
  };
  const [formAnsData, setFormAnsData] = useState({
    question: null,
    answer: "",
    ghost_user: "",
    answer_hour: 0,
    answer_minute: 0,
    answer_seconds: 0,
  });
  const [formData, setFormData] = useState({
    question: "",
    question_hour: "",
    question_minute: "",
    question_seconds: "",
    ghost_user_id: "",
  });
  const handleCloseModal = () => {
    setOpenAns(false);
  };

  useEffect(() => {
    if (editWebi == true) {
      getWebinarList();
    }
  }, []);
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    ></Box>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const hangleQuestionSubmit = async () => {
    if (!webinarId) {
      console.error("webinarId is missing or invalid");
      return;
    }
    if (!sessionUser || !sessionUser.access) {
      console.error("User is not authenticated");
      return;
    }
    if (!formData.question) {
      console.error("Please fill in all fields");
      toast.error("Please fill in all fields before submitting.");
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("webinar", webinarId);
    formDataToSend.append("question", formData.question);
    formDataToSend.append("question_hour", formData.question_hour);
    formDataToSend.append("question_minute", formData.question_minute);
    formDataToSend.append("question_seconds", formData.question_seconds);
    formDataToSend.append("ghost_user", formData.ghost_user_id);
    try {
      const val = await handleAddQuestions(sessionUser.access, formDataToSend);
      console.log("response", val);
      if (val.status === "success") {
        console.log("Question added successfully");
        toast.success(val.message);
        getWebinarList();
        setFormData({
          question: "",
          question_hour: "0",
          question_minute: "0",
          question_seconds: "0",
        });
        handleCloseAnsModalModal();
      } else {
        toast.error(val.message);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error(
        "An error occurred while adding the question. Please try again later."
      );
    }
  };
  const getWebinarList = async () => {
    try {
      await getQuesAndAnsList(sessionUser.access, webinarId)
        .then((value) => {
          console.log("value", value);
          const jsonData = value?.data;
          setData(jsonData);
          setFormData({
            question: "",
            question_hour: "0",
            question_minute: "0",
            question_seconds: "0",
            answer: "",
            ghost_user: "",
            answer_hour: "0",
            answer_minute: "0",
            answer_seconds: "0",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      toast.error(" Something API Issue");
    }
  };
  useEffect(() => {
    getWebinarList();
  }, []);
  const getGhostUsers = async () => {
    getGhostSelectedList(sessionUser.access, webinarId)
      .then((val) => {
        const jsonData = val.data;
        setGhostUsers(jsonData);
      })
      .catch((err) => {
        console.log("hvgcghcghcgf", err);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      getGhostUsers();
    }, 5000);
  }, []);

  const handleOpenAnsModel = (val) => {
    if (val.id != null) {
      console.log("val.id is not null:", val.id);
      setQuesId(val.id);
      console.log("quesid after setting:", val.id);
      SetShowQuestion(val.question);
      console.log("show ques:", val.question);
      setOpenAns(true);
    } else {
      console.log("val.id is null:", val.id);
    }
  };
  const handleAnsInputChange = (e) => {
    const { name, value } = e.target;
    setFormAnsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAnsSubmit = async () => {
    if (quesid == null) {
      console.error("quesid is null. Please set a valid question ID.");
      return;
    }
    const webinarId = localStorage.getItem("webinarId");
    const { answer, ghost_user, answer_hour, answer_minute, answer_seconds } =
      formAnsData;

    if (!answer || !ghost_user) {
      console.error("Please fill in all fields");
      toast.error("Please fill in all fields before submitting.");
      return;
    }
    if (!webinarId) {
      console.error("webinarId is missing or invalid");
      return;
    }

    if (!sessionUser || !sessionUser.access) {
      console.error("User is not authenticated");
      return;
    }
    const headers = {
      Authorization: `Bearer ${sessionUser.access}`,
    };
    const formDataToSend = new FormData();
    formDataToSend.append("webinar", webinarId);
    formDataToSend.append("question", quesid);
    formDataToSend.append("answer", answer);
    formDataToSend.append("ghost_user", ghost_user);
    formDataToSend.append("answer_hour", answer_hour);
    formDataToSend.append("answer_minute", answer_minute);
    formDataToSend.append("answer_seconds", answer_seconds);
    try {
      await handleAddAnswer(sessionUser.access, formDataToSend)
        .then((value) => {
          if (value.status === "success") {
            toast.success(value.message);
            console.log("Answer added successfully");
            setFormAnsData({
              question: null,
              answer: "",
              ghost_user: "",
              answer_hour: 0,
              answer_minute: 0,
              answer_seconds: 0,
            });
            getWebinarList();
            handleCloseModal();
          } else {
            toast.error(value.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error("Error adding answer:", error);
    }
  };
  const deleteAnswer = async (id) => {
    console.log(id, "items");
    try {
      await deleteAnswerList(sessionUser.access, id)
        .then((value) => {
          console.log("value", value);
          handleClose();
          getWebinarList();
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.error(`Error deleting webinar with ID ${id}:`, error);
    }
  };
  const deleteQues = async (id) => {
    try {
      await deleteQuestionsAnswerList(sessionUser.access, id).then((value) => {
        console.log("value", value);
        if (value.status === "success") {
          toast.success(value.message);
          handleClose();
          getWebinarList();
        } else {
          toast.error(value.message);
        }
      });
    } catch (error) {
      console.error(`Error deleting webinar with ID ${id}:`, error);
    }
  };
  return (
    <>
      <div>
        <div className="heading-events">
          <Typography variant="h4" gutterBottom className="heading-details">
            Dummy Question And Answer
          </Typography>
        </div>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <Grid item xs={6} md={6}>
            <div className="heading-quesans">
              <Typography variant="h5" className="heading-toggletxt">
                Add Question
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div>
              <div
                style={{ textAlign: "end", margin: "0px" }}
                className="btn-Addque"
              >
                <Button variant="contained" onClick={handleOpen}>
                  Add
                </Button>
              </div>
              <Modal
                open={open}
                onClose={handleCloseAnsModalModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    ...style,
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                  className="container-addQue"
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter Your Question"
                    variant="outlined"
                    className="ques-field"
                    name="question"
                    value={formData.question}
                    onChange={handleInputChange}
                  />
                  <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Guest Users
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="  Select Guest Users"
                            name="ghost_user_id"
                            value={formData.ghost_user_id}
                            onChange={handleInputChange}
                          >
                            {ghostUsers.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.first_name + " " + option.last_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" style={{    background: "white"}}>
                            Hours
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="question_hour"
                            value={formData.question_hour}
                            label="Age"
                            onChange={handleInputChange}
                            style={{background: "white"}} 
                          >
                            {Hours.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" style={{    background: "white"}}>
                            Minutes
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="question_minute"
                            value={formData.question_minute}
                            label="Age"
                            onChange={handleInputChange}
                            style={{background: "white"}} 
                          >
                            {Minutes.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" style={{    background: "white"}}>
                            Seconds
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="question_seconds"
                            value={formData.question_seconds}
                            label="Age"
                            onChange={handleInputChange}
                            style={{background: "white"}} 
                          >
                            {Seconds.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                            Seconds
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <div>
                    <div className="btn-Addque">
                      <Button
                        variant="contained"
                        onClick={hangleQuestionSubmit}
                      >
                        Add Question
                      </Button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </Grid>
        </Grid>
        {data?.map((item, index) => (
          <Card sx={{ margin: "32px 0px" }}>
            <CardContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={8}>
                    <h4 style={{ padding: "0px 14px" }}>
                      {" "}
                      Question {index + 1}
                    </h4>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <h4 className="align-time">
                      {item.question_hour?.toString().padStart(2, "0")}:
                      {item.question_minute?.toString().padStart(2, "0")}:
                      {item.question_seconds?.toString().padStart(2, "0")}
                    </h4>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <h4 style={{ padding: "0px 14px" }}>{item.question}</h4>
                  </Grid>
                </Grid>
                <Divider />
                <TableContainer
                  sx={{
                    overflowX: "auto",
                    cursor: "pointer",
                    border: "none!important",
                  }}
                >
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                          Answer
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          Users
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          Timing
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item?.ghost_user_answer?.map((items, index) => (
                        <TableRow key={items.id + index}>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {items.answer}
                          </TableCell>
                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                          ></TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            {items.ghost_user}
                          </TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            {items.answer_hour.toString().padStart(2, "0")}{" "}
                            <span style={{ marginRight: "6px" }}>:</span>
                            {items.answer_minute
                              .toString()
                              .padStart(2, "0")}{" "}
                            <span style={{ marginRight: "6px" }}>:</span>
                            {items.answer_seconds.toString().padStart(2, "0")}
                          </TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          ></TableCell>
                          <TableCell>
                            <div style={{ textAlign: "center" }}>
                              <Button onClick={() => deleteAnswer(items.id)}>
                                <DeleteIcon
                                  style={{ color: "red", textAlign: "center" }}
                                />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <div className="btn-Addque" style={{ textAlign: "start" }}>
                    <Button onClick={() => deleteQues(item.id)}>Delete</Button>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div className="btn-Addque" style={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleOpenAnsModel(item)}
                    >
                      Add Answer
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
        <Modal
          open={openAns}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              maxHeight: "80vh", 
              overflowY: "auto", 
            }}
            className="container-addQue"
          >
            <TextField
              id="outlined-basic"
              label="Your Question"
              variant="outlined"
              className="ques-field"
              name="question"
              disabled
              value={showQuestion}
              onChange={handleAnsInputChange}
            />

            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Guest Users
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="  Select Guest Users"
                      name="ghost_user"
                      value={formAnsData.ghost_user}
                      onChange={handleAnsInputChange}
                    >
                      {ghostUsers.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.first_name + " " + option.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <TextField
                id="outlined-basic"
                label="Enter Your Answer"
                variant="outlined"
                multiline
                maxRows={4}
                className="ques-field"
                name="answer"
                value={formAnsData.answer}
                onChange={handleAnsInputChange}
              />
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{background: "white"}} >Hours</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="answer_hour"
                      value={formAnsData.answer_hour}
                      onChange={handleAnsInputChange}
                      style={{background: "white"}}
                    >
                      {HoursAns &&
                        HoursAns.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{background: "white"}}    >
                      Minutes
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="answer_minute"
                      value={formAnsData.answer_minute}
                      onChange={handleAnsInputChange}              >
                      {MinutesAns &&
                        MinutesAns.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"      style={{background: "white"}}>
                      Seconds
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="answer_seconds"
                      value={formAnsData.answer_seconds}
                      onChange={handleAnsInputChange}
                 
                    >
                      {SecondsAns &&
                        SecondsAns.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <div>
              <div className="btn-Addque">
                <Button variant="contained" onClick={handleAnsSubmit}>
                  Add Answer
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EventsQueAns;
