export default {
  home: "/home",
  events: "/events",
  normaluser:"/users",
  setting: "/setting",
  signup: "/signup",
  login: "/login",
  webinarView: "/webinarView",
  webinarEdit: "/webinarEdit",
  webinarJoin: "/webinarJoin",
  webinarAnalytics: '/webinarAnalytics',
  chatReport: "/chatReport",
  webinarAttendSuccess: "/webinarAttendSuccessfully",
  errorpage:"/*",
  recoverPassword:"/recoverPassword",
  forgetPassword:"/forgetPassword",
  webinarGhostUser:"/webinarGhostUser"

};