import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Person2Icon from "@mui/icons-material/Person2";
import UndoIcon from "@mui/icons-material/Undo";
import { Avatar } from "@mui/material";
import Lists from "./Lists";
import Layout from "../common/layout";
import colors from "../../utils/constant/colors";
import "../home/style.css";
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const tabPanelStyle = {
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={tabPanelStyle}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function HomeTab() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Layout showSideBar active={1}>
        <Helmet>
          <title>Letswebinar | Home</title>
        </Helmet>
        <div style={{ display: "flex", overflowY: "auto", overflowX: "auto" }}>
          <Box
            sx={{
              bgcolor: "background.paper",
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                style={{ borderRight: "1px solid #c3c3c3" }}
                className="custom-h"
              >
                <div className="tabs-home">
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className="tabs-homes"
                    sx={{
                      margin: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <Tab
                      icon={
                        <Avatar
                          sx={{ bgcolor: value === 0 ? colors.primary : " " }}
                          style={{ marginRight: "16px", marginLeft: "0px" }}
                        >
                          <Person2Icon />
                        </Avatar>
                      }
                      className="tabs-align"
                      iconPosition="start"
                      label="All Events"
                      {...a11yProps(0)}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: "20px",
                      }}
                    />
                    <Tab
                      icon={
                        <Avatar
                          sx={{ bgcolor: value === 1 ? colors.primary : " " }}
                          className="avatar1"
                        >
                          <AccessTimeIcon />
                        </Avatar>
                      }
                      iconPosition="start"
                      label="Active"
                      {...a11yProps(1)}
                      className="tabs-align1"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "40px",
                      }}
                    />
                    <Tab
                      icon={
                        <Avatar
                          sx={{ bgcolor: value === 2 ? colors.primary : " " }}
                          className="avatar2"
                        >
                          <UndoIcon />
                        </Avatar>
                      }
                      iconPosition="start"
                      label="InActive"
                      {...a11yProps(2)}
                      className="tabs-align2"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: "0px",
                      }}
                    />
                  </Tabs>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{ borderLeft: " 1px solid #d3d3d3" }}
              >
                <div>
                  <div className="scroll-home1">
                    <p>
                      <b>Please Note</b> : To quickly join our webinar, you can
                      use the following format for the URL:
                    </p>
                    <p>
                      https://webinarJoin/webinar_id?name=YourName&mobile=YourMobileNumber&email=YourEmailAddress.
                    </p>           
                  </div>
                  <div className="scroll-home">
                    <TabPanel value={value} index={0}>
                      <div>
                        <Lists type={"all"} />
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Lists type={"active"} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Lists type={"inactive"} />
                    </TabPanel>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Layout>
    </>
  );
}
