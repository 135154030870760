import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Layout from "../common/layout";
import { useDispatch, useSelector } from "react-redux";
import routerPath from "../../utils/router/routerPath";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { UserSignUp } from "../../utils/apis/apis";
import constvalue from "../../utils/constant/constvalue";
import { Helmet } from "react-helmet";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const SignUp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const [btnVisible, setBtnVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("You must enter first name"),
      last_name: Yup.string().required("You must enter last name"),
      phone_number: Yup.string()
        .matches(/^[0-9]*$/, "Phone number must contain only numeric digits")
        .required("You must enter phone number")
        .max(10, "10 digits required."),
      email: Yup.string()
        .email("You must enter a valid email")
        .matches(constvalue.regexEmail, "You must enter a valid email")
        .required("You must enter an email"),

      password: Yup.string()
        .required("Please enter your password.")
        .min(8, "Password is too short - should be 8 characters minimum."),
      passwordConfirm: Yup.string()
        .required("Please enter your Confirm password.")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      agreeTerms: Yup.boolean()
        .oneOf([true], "You must agree to the terms and conditions")
        .required("You must agree to the terms and conditions"), // Add the required validation
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        const formData = new FormData();
        formData.append("first_name", values.first_name);
        formData.append("last_name", values.last_name);
        formData.append("email", values.email);
        formData.append("phone_number", values.phone_number);
        formData.append("password", values.password);
        formData.append("passwordConfirm", values.passwordConfirm);
        setLoading(true);
        setBtnVisible(false);
        await UserSignUp(formData)
          .then((value) => {
            if (value.status === "success") {
              console.log("Sign-up successful!");

              navigate(routerPath.home);

              toast.success(value.message);
              setLoading(false);

              resetForm();
            } else {
              toast.error(value.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
            console.log("err", err);
          });
      } catch (error) {
        console.error("API request error:", error);
        setErrorMessage("An error occurred during sign-up.");
      }
    },
  });

  return (
    <Layout buttonName={"Login"}>
      <Helmet>
        <title>Letswebinar | signup</title>
      </Helmet>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0rem 0rem 0rem",
        }}
        className="height-auth"
      >
        <Box
          sx={{
            px: 3,
            py: "100px",
          }}
          className="box-form-signup"
        >
          <div>
            <Stack spacing={1} sx={{ mb: 3 }} className="card-stack-heading">
              <Typography
                variant="h4"
                className="heading-form"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Tell us more about yourself
              </Typography>
              <Typography
                style={{ textAlign: "center" }}
                color="text.secondary"
                variant="body2"
                className="m-5 sub-heading-form"
              >
                Enter your details to proceed further
              </Typography>
              <Typography
                className="errorMessage"
                sx={{ color: "red", textAlign: "center" }}
              >
                {errorMessage}
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <div className="text-field">
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    id="standard-textarea"
                    label="Email"
                    placeholder="Enter Email"
                    variant="standard"
                    type="email"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Stack>

              <Stack direction="row" spacing={2} >
                <div className="text-field w-textField">
                  <TextField
                    error={
                      !!(formik.touched.first_name && formik.errors.first_name)
                    }
                    fullWidth
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    id="standard-textarea"
                    label="First Name"
                    placeholder=" Enter First Name"
                    variant="standard"
                    type="text"
                    name="first_name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                    style={{marginTop:'14px'}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="text-field w-textField">
                  <TextField
                    error={
                      !!(formik.touched.last_name && formik.errors.last_name)
                    }
                    fullWidth
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    id="standard-textarea"
                    label="Last Name"
                    placeholder=" Enter Last Name"
                    variant="standard"
                    type="text"
                    name="last_name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                    style={{marginTop:'14px'}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Stack>

              <div className="text-field  ">
                <TextField
                  id="standard-basic"
                  label="Phone no"
                  variant="standard"
                  type="tel"
                  error={
                    !!(
                      formik.touched.phone_number && formik.errors.phone_number
                    )
                  }
                  fullWidth
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  placeholder=" Enter Phone Number"
                  name="phone_number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                  style={{marginTop:'14px'}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneAndroidIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </div>

              <div className="text-field ">
                <TextField
                  id="standard-basic"
                  variant="standard"
                  name="password"
                  label="Password"
                  placeholder="Enter Password"
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <VisibilityIcon
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="text-field ">
                <TextField
                  id="standard-basic"
                  variant="standard"
                  name="passwordConfirm"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  style={{marginTop:'15px'}}
                  value={formik.values.passwordConfirm}
                  error={
                    !!(
                      formik.touched.passwordConfirm &&
                      formik.errors.passwordConfirm
                    )
                  }
                  fullWidth
                  helperText={
                    formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm
                  }
                  type={confirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {confirmPassword ? (
                          <VisibilityIcon
                            onClick={() => setConfirmPassword(!confirmPassword)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setConfirmPassword(!confirmPassword)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}

              <Stack
                direction="row"
                spacing={2}
                style={{marginTop:'14px'}}
                sx={{ margin: "14px 0px 0px -10px;" }}
              >
                <Checkbox
                  {...label}
                  checked={formik.values.agreeTerms}
                  onChange={(e) =>
                    formik.setFieldValue("agreeTerms", e.target.checked)
                  }
                  className="checkbox"
                  required
                  sx={{ margin: "6px 11px;" }}
                />

                <p
                  className="agree-statement mt-12 conditonTitle"
                  style={{ marginTop: "11px", marginLeft: "0px" }}
                >
                  I agree with terms & conditions
                </p>
              </Stack>
              {formik.errors.agreeTerms && (
                <Typography color="error" sx={{ mt: 1 }} variant="body2">
                  {formik.errors.agreeTerms}
                </Typography>
              )}
              <div className="bg-btncolor">
                {loading ? (
                  <CircularProgress
                    style={{
                      textAlign: "center",
                      margin: "auto",
                      display: "grid",
                    }}
                  />
                ) : (
                  <Button
                    fullWidth
                    size="large"
                    sx={{
                      mt: 3,
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Sign Up
                  </Button>
                )}
              </div>
            </form>
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default SignUp;
