import * as React from "react";
import "../chatReport/style.css";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Layout from "../common/layout";
import { useSelector } from "react-redux";
import RegistrantsTables from "./RegistrantsTables";
import SessionTable from "./SessionTable";
import {
  getFilterData,
  getSearchData,
  webAnalytics,
  webAnalyticsSession,
} from "../../utils/apis/apis";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { IoSearchSharp } from "react-icons/io5";
import routerPath from "../../utils/router/routerPath";
export default function WebinarAnalytics() {
  const { webinarId } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  const [webinarRegistrationData, SetwebinarRegistrationData] = React.useState(
    []
  );
  const [selectedPercentage, setSelectedPercentage] = React.useState("");
  const [searchName, setSearchName] = React.useState("");
  const [searchFilter, setSearchFilter] = React.useState("");
  const rowsPerPage = 5;
  const rowsPerPageSessiion = 3;
  const navigate = useNavigate();
  const [regPages, setRegPages] = React.useState(1);
  const [regPages1, setRegPages1] = React.useState(1);
  const [regPages2, setRegPages2] = React.useState(1);
  const [pageSess, setPageSess] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [totalData, setTotalData] = React.useState(0);
  const [totalRows1, setTotalRows1] = React.useState(0);
  const [totalRowsSearch, setTotalRowsSearch] = React.useState(1);
  const [showPage, setShowPage] = React.useState();
  const [showPageSea, setShowPageSea] = React.useState();
  const [showPageFilter, setShowFilter] = React.useState();
  const [showPage1, setShowPage1] = React.useState(true);
  const [showPageSea1, setShowPageSea1] = React.useState();
  const [showPageFilter1, setShowFilter1] = React.useState();
  const [pagesession, setPageSession] = React.useState(1);
  const [totalRowssession, setTotalRowssession] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [valueFilter, setValueFilter] = React.useState("");
  const [yesval, setYesval] = React.useState("");
  const [totalDataSearch, setTotalDataSearch] = React.useState(0);
  const [totalDataFilter, setTotalDataFilter] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const param = useParams();
  const searchId = param.webinarId;
  const [inpse, setInsp] = React.useState("");
  const handleRegpagination = (event, page) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    getDataPagination(page);
    setRegPages(page);
    setShowPage(false);
    setShowPageSea(true);
    setShowPage1(false);
    setShowPageSea1(true);
    setShowFilter(false);
    if (page === totalPages) {
      getData(page + 1);
    }
  };
  const handleSearchPagination = (event, page) => {
    setRegPages1(page);
    handleSearch(searchName, page);
  };
  const handleFilterPagination = async (event, page) => {
    try {
      handleChangeFilter({ target: { value: selectedFilter } }, page);
      setRegPages2(page);
    } catch (error) {}
  };
  const fetchData = async (
    page,
    analyticsFunction,
    setDataFunction,
    setTotalFunction
  ) => {
    try {
      const nextPageToFetch = nextPage || page;
      const value = await analyticsFunction(
        sessionUser.access,
        webinarId,
        nextPageToFetch
      );
      if (value.status === "error" && value.message === "webinar not found") {
      } else if (value.status === "success") {
        setDataFunction(value?.webinar_registration_data);
        setTotalFunction(value?.total_pages);
        setTotalData(value?.total_attendee_length);
        setShowPage(true);
        setLoading(false);
        setShowPageSea(false);
        setShowFilter(false);
        setShowPage1(true);
        setShowPageSea1(false);
        setShowFilter1(false);
        console.log("valuevaluevalue", value?.webinar_registration_data);
      }
    } catch (err) {
      setLoading(false);
      console.error("error", err);
    }
  };

  const getData = async (page) => {
    await fetchData(
      page,
      webAnalytics,
      SetwebinarRegistrationData,
      setTotalRows
    );
  };
  const getDataPagination = async (page) => {
    try {
      const nextPageToFetch = page;
      const value = await webAnalytics(
        sessionUser.access,
        webinarId,
        nextPageToFetch
      );
      SetwebinarRegistrationData(value?.webinar_registration_data);
      setTotalRows(value?.total_pages);
      setTotalRowsSearch(value?.total_pages_in_search);
      setTotalRowssession(value?.session_total_pages);
      setShowPage(true);
      setLoading(false);
      setShowPageSea(false);
      setShowFilter(false);
      setShowPage1(true);
      setShowPageSea1(false);
      setShowFilter1(false);
    } catch (error) {
      setLoading(false);
      console.error("error", error);
    }
  };
  const handleChangeFilter = async (event, page = 1) => {
    setSearchText("");
    const selectedValue = event.target?.value;
    setSelectedPercentage(selectedValue);
    setValueFilter(selectedValue);
    setSelectedFilter(selectedValue);
    const isPercentageRange = /^(\d+-\d+)$/.test(selectedValue);
    try {
      const response = await getFilterData(
        sessionUser.access,
        webinarId,
        selectedValue,
        event,
        page
      );
      if (response?.webinar_registration_data == "not found") {
        SetwebinarRegistrationData([]);
        setTotalDataFilter(0);
        setShowFilter(false);
        setShowPage(false);
        setShowPageSea(false);
      } else {
        SetwebinarRegistrationData(response.webinar_registration_data);
        setTotalRows1(response.total_pages_in_search);
        setTotalDataFilter(response.total_attendee_length);
        setShowFilter(true);
        setShowPage(false);
        setShowPageSea(false);
        setShowFilter1(true);
        setShowPage1(false);
        setShowPageSea1(false);
        console.warn("response", response.webinar_registration_data);
      }
    } catch (error) {}
  };
  const handleSearch = async (e, page = 1) => {
    if (e.length === 0) {
      getDataPagination();
    } else {
      try {
        const nextPageToFetch = nextPage || page;
        const response = await getSearchData(
          sessionUser.access,
          webinarId,
          e,
          page,
          valueFilter,
          nextPageToFetch
        );
        console.log("Total Pages in Search:", response.total_pages_in_search);
        if (response.webinar_registration_data === "not found") {
          SetwebinarRegistrationData([]);
          setTotalDataSearch(0);
          setShowFilter(false);
          setShowPage(false);
          setShowPageSea(false);
        } else {
          setShowPage(false);
          setShowFilter(false);
          setShowPageSea(true);
          setShowPage1(false);
          setShowFilter1(false);
          setShowPageSea1(true);
          SetwebinarRegistrationData(response.webinar_registration_data);
          setTotalRowsSearch(response.total_pages_in_search);
          setTotalDataSearch(response.total_attendee_length);
          setSearchName(e);
        }
      } catch (error) {
        console.error("Error in handleSearch:", error);
      }
    }
  };
  const resetTable = () => {
    setValueFilter("");
    getData();
    setRegPages(1);
    setSearchText("");
  };
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid>
          <Layout showSideBar active={0}>
            <div
              className="container-analytics"
              style={{ height: "auto", overflow: "hidden" }}
            >
              <Typography
                variant="h4"
                margin={3}
                gutterBottom
                style={{ textAlign: "start" }}
              >
                Webinar Analytics
              </Typography>

              <div className="maincon">
                <Grid container>
                  <Grid container className="gridInstruct">
                    <p style={{ overflowWrap: "anywhere" }}>
                      <b>Please Note</b>: Enhance your webinar experience by
                      using various tags. Include them in the URL using the
                      following format:
                    </p>
                    <p style={{ overflowWrap: "anywhere", lineHeight: "1.6" }}>
                      <b>Example URL with tags </b>:
                      https:///webinarJoin/webinar_id?tag=webinar_tag&tag_user=user123&tag_company=companyX&tag_custom=customTag1&tag_source=google_ads&tag_medium=social_media&tag_campaign=spring_campaign&tag_content=video_session&tag_term=technology
                    </p>
                  </Grid>
                </Grid>

                <SessionTable
                  getData={getData}
                  pagesession={pagesession}
                  totalRowssession={totalRowssession}
                ></SessionTable>

                <RegistrantsTables
                  data={webinarRegistrationData}
                  showPageSea={showPageSea}
                  showPage={showPage}
                  showPageSea1={showPageSea1}
                  showPage1={showPage1}
                  totalRows={totalRows}
                  totalData={totalData}
                  totalDataSearch={totalDataSearch}
                  regPages={regPages}
                  regPages1={regPages1}
                  regPages2={regPages2}
                  handleRegpagination={handleRegpagination}
                  handleSearchPagination={handleSearchPagination}
                  valueFilter={valueFilter}
                  selectedPercentage={selectedPercentage}
                  rowsPerPage={rowsPerPage}
                  totalRows1={totalRows1}
                  showPageFilter={showPageFilter}
                  showPageFilter1={showPageFilter1}
                  pagesession={pagesession}
                  handleFilterPagination={handleFilterPagination}
                  searchText={searchText}
                  totalRowsSearch={totalRowsSearch}
                  totalDataFilter={totalDataFilter}
                  setSearchText={(val) => {
                    setValueFilter("");
                    setSearchText(val);
                  }}
                  resetTable={resetTable}
                  handleChangeFilter={handleChangeFilter}
                  fun={handleSearch}
                  searchVal={searchName}
                  ser={setSearchName}
                />
              </div>
            </div>
          </Layout>
        </Grid>
      )}
    </>
  );
}
