import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
import images from "../../utils/constant/images";
import "./style.css";
const LandingPage = ({ history }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate(routerPath.home);
    }, 3000);
    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [history]);
  return (
    <div
      className="landing-page"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img src={images.logo} alt="Logo" />
      </div>
      <div>
        <h1>Welcome to Let's Webinar</h1>
      </div>
    </div>
  );
};

export default LandingPage;
