import React, { useState, useEffect } from "react";
import { Box, Button, Chip, Divider, Stack, Switch } from "@mui/material";
import * as Yup from "yup";
import QuizIcon from "@mui/icons-material/Quiz";
import { useNavigate, useParams } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
import Header from "../common/layout/Header";
import { Formik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import Layout from "../common/layout";
import EventVideo from "../events/EventVideo";
import EventGeneralInfo from "../events/EventGeneralInfo";
import EventsOthers from "../events/EventsOthers";
import EventsLive from "../events/EventsLive";
import { Helmet } from "react-helmet";
import "./style.css";
import { useDispatch } from "react-redux";
import {
  createWebinar,
  getGhostSelectedList,
  updateWebinar,
  uploadVideoFile,
  webinarUpdateData,
} from "../../utils/apis/apis";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import Menu from "@mui/material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { videoDeleteFile, videoSegments } from "../../utils/apis/apis";
import EventsQueAns from "../events/EventsQueAns";
import EventsGhostUsers from "../events/EventsGhostUsers";
import { FormControlLabel, Typography } from "@mui/material";
export default function WebinarEdit() {
  const [value, setValue] = React.useState(0);
  const [option, setOption] = React.useState("select");
  const [apiData, setApiData] = useState({});
  const [showWebinarVideos, setShowWebinarVideos] = useState(true);
  const { webinarId } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  const [progress, setProgress] = React.useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEditVideo, setShowEditVideo] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [ghostUsers, setGhostUsers] = useState([]);
  const [receivedIds, setReceivedIds] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(true);
  const [totalGhostUser, setTotalGhostUser] = useState(receivedIds);
  const [selectGhostUser, setSelectedGhostUser] = useState([]);
  const [Selectedd, setSelectedd] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  const [value4, setValue4] = useState(null);
  const [valuesArray, setValuesArray] = useState([]);
  const param = useParams();
  const editId = param.webinarId;
  const handleEditVideoClick = () => {
    setShowEditVideo(true);
    setShowWebinarVideos(false);
  };
  useEffect(() => {
    getWebinarData();
  }, []);

  const getWebinarData = async () => {
    setLoading(true);
    await webinarUpdateData(sessionUser.access, editId)
      .then((response) => {
        setApiData(response.data);
        const isData = JSON.parse(response.data.resolution_status);
        isData.map((val) => {
          if (val === 0) {
            setIsChecked(true);
          } else if (val === 1) {
            setIsChecked1(true);
          } else if (val === 2) {
            setIsChecked2(true);
          } else if (val === 3) {
            setIsChecked3(true);
          } else {
          }
        });
        setLoading(false);
        if (response && response.data) {
          const jsonData = response.data;
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handlePrevClick = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };
  const handleCreateClick = () => {
    navigate(routerPath.home);
  };
  const initialValues = {
    name: apiData?.name || "",
    title: apiData?.title || "",
    select: "",
    urlPath: apiData.video_link || "",
    directory: "",
    directoryAndUploadedVideoData: "",
    uploadvideo: apiData?.uploaded_video_file || "",
    toggle_question: false,
    toggle_live_chat: false,
    schedule_time: apiData.schedule_time,
    selected_ghost_user: apiData.selected_ghost_user,
    resolution_status: apiData.resolution_status,
    webinarScheduleDMW: {
      daily: apiData.is_daily,
      monthly: apiData.is_monthly,
      weekly: apiData.is_weekly,
    },
    status: apiData?.status || " ",
  };
  const selectedGhostUser = apiData.selected_ghost_user;
  const handleChildData = (data) => {
    setReceivedIds(data);
    setIsOpen(false);
    let original_array = selectedGhostUser;
    let new_array = data;
    original_array = new_array;
    setSelectedGhostUser(JSON.stringify(original_array));
  };
  useEffect(() => {
    if (selectedGhostUser) {
      setSelectedGhostUser(selectedGhostUser);
    }
  }, [selectedGhostUser]);

  const handleClear = () => {
    setSelectedGhostUser("[]");
    setReceivedIds("[]");
    setGhostUsers([]);
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, "min 3 characters")
      .required(
        <span style={{ color: "red", fontSize: "15px" }}>
          Please Enter Name
        </span>
      ),
    title: Yup.string()
      .trim()
      .required(
        <span style={{ color: "red", fontSize: "15px" }}>
          Please Enter Title
        </span>
      )
      .min(3, "minimum 3 characters"),
    select:
      option === "select"
        ? Yup.string().required(
            <span style={{ color: "red", fontSize: "15px" }}>
              Please Select any One
            </span>
          )
        : null,
    urlPath:
      option === "urlPath"
        ? Yup.string()
            .trim()
            .required(
              <span style={{ color: "red", fontSize: "15px" }}>
                Please Enter URL
              </span>
            )
        : null,
    uploadvideo:
      option === "uploadvideo"
        ? Yup.string().required("Please Select Uploaded Video")
        : null,
    directory:
      option === "directory"
        ? Yup.string().required(" Please Select Directory Video")
        : null,
  });
  const getGhostUsers = async () => {
    getGhostSelectedList(sessionUser.access, webinarId)
      .then((val) => {
        const jsonData = val.data;
        setGhostUsers(jsonData);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setTimeout(() => {
      getGhostUsers();
    }, 1000);
  }, []);

    const submits = async (val) => {
      const temp = [];
      if (isChecked === true) {
          temp.push(0);
      }
      if (isChecked1 === true) {
          temp.push(1);
      }
      if (isChecked2 === true) {
          temp.push(2);
      }
      if (isChecked3 === true) {
          temp.push(3);
      }
      try {
          const formData = {
              webinar_id: webinarId,
              name: val.name,
              title: val.title,
              video_link: val.urlPath,
              video_type: val.urlPath ? "link" : val.directoryAndUploadedVideoData.videoType,
              video_file: val.directoryAndUploadedVideoData.videoId ? val.directoryAndUploadedVideoData.videoId : apiData.video_file || "",
              schedule_time: val.schedule_time ? val.schedule_time : apiData.schedule_time,
              is_daily: val.webinarScheduleDMW.daily,
              is_weekly: val.webinarScheduleDMW.weekly,
              is_monthly: val.webinarScheduleDMW.monthly,
              status: val.status,
              selected_ghost_user: selectGhostUser,
              resolution_status: JSON.stringify(temp),
          };
          await updateWebinar(sessionUser.access, formData)
              .then((val) => {
                  handleCreateClick();
              })
              .catch((err) => {});

          handleCreateClick();
      } catch (error) {
          navigate(routerPath.home);
      }
  };
  useEffect(() => {
    updateValuesArray();
  }, [isChecked, isChecked1, isChecked2,isChecked3]);

  const handleChange0 = (event) => {
    setIsChecked(event.target.checked);
    setValue1(event.target.checked ? 0 : null);
  };

  const handleChange1 = (event) => {
    setIsChecked1(event.target.checked);
    setValue2(event.target.checked ? 1 : null);
  };

  const handleChange2 = (event) => {
    setIsChecked2(event.target.checked);
    setValue3(event.target.checked ? 2 : null);
  };
  const handleChange3 = (event) => {
    setIsChecked3(event.target.checked);
    setValue4(event.target.checked ? 3 : null);
  };

  const updateValuesArray = () => {
    const newArray = [];
    if (isChecked) newArray.push(value1);
    if (isChecked1) newArray.push(value2);
    if (isChecked2) newArray.push(value3);
    if (isChecked3) newArray.push(value4);
    setValuesArray(newArray);
  };

  const [switches, setSwitches] = React.useState({});
  const handleSwitchToggle = (switchName) => {
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [switchName]: !prevSwitches[switchName],
    }));
  };
  return (
    <>
      <Helmet>
        <title>Letswebinar | webinarEdit</title>
      </Helmet>
      {!loading && (
        <Layout showSideBar active={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema[value]}
            onSubmit={submits}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => {
              return (
                <>
                  <div className="container-Editdata" style={{}}>
                    <EventGeneralInfo
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      webinarId={webinarId}
                    />
                    <Divider style={{ margin: "3rem 0px" }} />
                    <div>
                      <div className="display-video">
                        {showWebinarVideos && <h2>Webinar Videos</h2>}
                        <div className="btn-editvideo">
                          <Button onClick={handleEditVideoClick} sty>
                            Edit Video
                          </Button>
                        </div>
                      </div>

                      <div style={{ width: "97%" }}>
                        {showEditVideo && (
                          <EventVideo
                            webinarId={webinarId}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            option={option}
                            setOption={(op) => {
                              setOption(op);
                            }}
                            progress={progress}
                          />
                        )}
                      </div>
                    </div>

                    <div style={{ width: "99%" }}>
                      {showWebinarVideos && (
                        <Card sx={{ minWidth: 275, margin: "12px 0px" }}>
                          <CardContent>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={2} sm={2} md={2}>
                                  <MusicVideoIcon className="music-icon" />
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                  <Grid container>
                                    <Grid item xs={12} md={12}>
                                      <h4
                                        className="file-name"
                                        style={{
                                          wordWrap: "break-word",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {apiData
                                          ? apiData.uploaded_video_file
                                          : "Loading..."}
                                      </h4>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                      )}
                    </div>
                    <Divider style={{ margin: "3rem 0px" }} />
                    <div style={{ width: "99%" }}>
                      <EventsLive
                        webinarId={webinarId}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setShowForm={false}
                        setOption={(op) => {
                          setOption(op);
                        }}
                        editable={true}
                      />
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        display="flex"
                        alignItems="center"
                        mt={3}
                      >
                        <Grid item xs={4}>
                          <Typography>Select Video Resolutions</Typography>
                        </Grid>
                        <Grid item xs={8} textAlign="right">
                          <Grid container>
                            <Grid item xs={12} sm={6} lg={3}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isChecked}
                                    onChange={handleChange0}
                                    value={value1}
                                  />
                                }
                                label="352x240"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isChecked1}
                                    onChange={handleChange1}
                                    value={value2}
                                  />
                                }
                                label="824x480"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isChecked2}
                                    onChange={handleChange2}
                                    value={value3}
                                  />
                                }
                                label="1280x720"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isChecked3}
                                    onChange={handleChange3}
                                    value={value4}
                                  />
                                }
                                label="1920x1080"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <Divider style={{ margin: "2rem 0px" }} />

                    <div style={{ width: "99%" }}>
                      <div className="btn-editvideo">
                        <Button onClick={handleClear}>Clear</Button>
                      </div>
                      <EventsGhostUsers
                        webinarId={webinarId}
                        onChildData={handleChildData}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setOption={(op) => {
                          setOption(op);
                        }}
                        editable={true}
                        style={{ width: "60%" }}
                        ghostUsers={ghostUsers}
                      />
                    </div>
                    {isOpen && (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          margin: " 12px 12px",
                          overflow: "auto",
                          maxHeight: "150px",
                        }}
                      ></Box>
                    )}
                    <Divider style={{ margin: "2rem 0px" }} />
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "2rem",
                      }}
                    >
                      <p
                        className="agree-statement mt-12 conditonTitle"
                        style={{ marginTop: "11px", marginLeft: "0px" }}
                      >
                        Webinar Status {values.status}
                      </p>
                      <div>
                        <Switch
                          checked={values.status === "active"}
                          onChange={(event) => {
                            console.log("Switch value changed");
                            const newStatus =
                              values.status === "active"
                                ? "inactive"
                                : "active";
                            setFieldValue("status", newStatus);
                          }}
                          disabled={switchDisabled}
                        />
                      </div>
                    </Stack>
                    <div style={{ width: "99%" }}>
                      <EventsQueAns webinarId={webinarId} editWebi={true} />
                    </div>
                    <div className="btn-update" style={{ marginTop: "4rem" }}>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Layout>
      )}
    </>
  );
}
