import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  TextField,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { Textarea } from "@mui/joy";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function ChatMassage({ send, webinarChats, webinarId, isAdminUser }) {
  const sessionUser = useSelector((state) => state.session.user);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const handleInputChange = (event) => {
    setNewMessage(event.target.value);
  };
  const handleSendMessage = () => {
    if (newMessage) {
      send(newMessage);
      setNewMessage("");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (newMessage) {
        handleSendMessage();
      }
    }
  };
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [webinarChats]);
  return (
    <section class="msger height-cht">
      <main class="msger-chat">
        <Box className="main-box" sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List className="list-message">
            {webinarChats.map((message, index) => {
              const userDate = message.created_at
                ? message.created_at
                : message.date;
              const utcDate = new Date(userDate);
              const istDate = new Date(
                utcDate.getTime() + 5.5 * 60 * 60 * 1000
              );
              const isDate = new Date(istDate);
              const username = message.user_name
                ? message.user_name
                : message.user;
              const dfdf = sessionUser.first_name == message.user;
              const hour =
                isDate.getHours() < 10
                  ? "0" + isDate.getHours()
                  : isDate.getHours();
              const minute =
                isDate.getMinutes() < 10
                  ? "0" + isDate.getMinutes()
                  : isDate.getMinutes();
              const convertDate = hour + ":" + minute;
              const userMsg = message.message ? message.message : message.msg;
              const userName =
                sessionUser.id == message.user_id ? "You" : username;
              return (
                <>
                  {message.msg_type == "user_join" && (
                    <p className="join-chat">{userName} have joined</p>
                  )}

                  {message.msg_type == "chat_msg" && (
                    <ListItem key={index} className="list-item-message">
                      <Avatar
                        alt={username?.toUpperCase()}
                        src={username}
                        sx={{ marginRight: "2px" }}
                      />
                      <ListItemText
                        style={{ color: "white" }}
                        className="list-item-text-message"
                        primary={userMsg}
                        secondary={` ${userName} ${convertDate}`}
                        sx={{ textAlign: "right" }}
                        primaryTypographyProps={{
                          sx: { textAlign: "left", color: "white" },
                        }}
                        secondaryTypographyProps={{ sx: { color: "white" } }}
                      />
                    </ListItem>
                  )}
                </>
              );
            })}
            <div style={{ color: "white" }} ref={messagesEndRef}></div>
          </List>
        </Box>
      </main>
      <Grid
        container
        spacing={2}
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={11}
          xs={10}
          style={{ width: "100%" }}
        >
          <TextField
            placeholder="Type Message..."
            name="name"
            fullWidth
            multiline
            className="container-field"
            onKeyPress={handleKeyPress}
            value={newMessage}
            onChange={handleInputChange}
            style={{
              "&:focus": {
                outline: "none !important",
                border: "none !important",
                boxShadow: "none !important",
              },
              border: "1px solid grey",
              maxHeight: "100px",
              overflowY: "auto",
            }}
          />
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={1}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#6B59CC",
            borderRadius: "0px 13px 13px 0px",
            paddingTop: "0",
          }}
        >
          <div className="send-button">
            <Button
              variant="contained"
              style={{ border: "none" }}
              onClick={handleSendMessage}
              endIcon={
                <SendIcon
                  className="ico-send"
                  style={{ zIndex: "2", color: "white" }}
                ></SendIcon>
              }
            ></Button>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
export default ChatMassage;
