// import { MenuItem } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
// import MusicVideoIcon from "@mui/icons-material/MusicVideo";
// import Menu from "@mui/material/Menu";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import "../events/style.css";
// import { useSelector } from "react-redux";
// import { videoDeleteFile, videoSegments } from "../../utils/apis/apis";
// const VideoList = ({
//   videoList,
//   videoType,
//   setProcess,
//   selectedValue,
//   defultValueSelected,
// }) => {
//   const [anchorEl, setAnchorEl] = useState({});
//   const handleClick = (event, itemId) => {
//     setAnchorEl({ ...anchorEl, [itemId]: event.currentTarget });
//   };
//   const handleClose = (itemId) => {
//     setAnchorEl({ ...anchorEl, [itemId]: null });
//   };
//   const [selectedOptionError, setSelectedOptionError] = React.useState("");
//   const [selectedOption, setSelectedOption] = useState("select");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [selectedState, setSelectedState] = useState(null);
//   const sessionUser = useSelector((state) => state.session.user);
//   const [progress, setProgress] = useState(100);
//   const [showProgress, setShowProgress] = useState(false);
//   const [uplodVideoLists, setUplodVideoLists] = useState([]);
//   const [errorVideo, setErrorVideo] = useState("");
//   const isVideoType = videoType == "Use Directory" ? "directory" : "uploade";
//   const handleMenuItemClick = (id, index) => {
//     console.warn("Selected Video ID:", id);
//     setSelectedState(index);
//     selectedValue({ videoType: isVideoType, videoId: id });
//     handleClose(index);
//   };
//   // console.warn("Selected State:", selectedState);

//   useEffect(() => {
//     const aaaa = defultValueSelected?.videoId ? true : false;
//     if (defultValueSelected?.videoId) {
//       setSelectedState(defultValueSelected?.videoId);
//     } else {
//       setSelectedState(null);
//     }
//     console.log("defultValueSelected", aaaa);
//   }, []);
  
//   const processVideoSegment = async (id,folder_name) => {
//     const formData = new FormData();
//     formData.append("id", id);
//     formData.append("type", videoType);
//     formData.append("user_id", sessionUser.id);
//     formData.append("folder_name", folder_name);
//     console.log("formData", id, videoType);
//     handleClose(id);
//     setTimeout(() => {
//       setProcess(true);
//     }, 2000);
//     await videoSegments(formData)
//       .then((val) => {
//         setProcess(true);
//         console.log("Upload successful", val);
//       })
//       .catch((err) => {
//         console.error("Upload error:", err);
//       });
//   };
//   const deleteVideo = async (id, folder_name) => {
//     await videoDeleteFile(sessionUser.access, id, isVideoType, folder_name)
//       .then((val) => {
//         handleClose(id);
//         setProcess(true);
//       })
//       .catch((err) => {
//         console.error("Upload error:", err);
//       });
//   };
//   return (
//     <>
//       {videoList.map((items, index) => {
//         console.warn("itemsitems", items.id);
//         console.warn("index", index);
//         // console.log(
//         //   "items.segment_completed items.segment_completed 11111111111111",
//         //   items.segment_completed
//         // );
//         return (
//           <Card sx={{ minWidth: 275, margin: "12px 0px" , order: index }} key={`${index}index`}>
//             <CardContent sx={{ paddingLeft: "25px" }}>
//               <Box sx={{ flexGrow: 1 }}>
//                 <Grid container spacing={2} alignItems="center">
//                   <Grid item xs={2} sm={2} md={2}>
//                     <MusicVideoIcon className="music-icon" />
//                   </Grid>
//                   <Grid item xs={8} sm={8} md={8}>
//                     <Grid container className="videoname">
//                       <Grid item xs={12} md={12}>
//                         <h4
//                           className="file-name"
//                           style={{
//                             wordWrap: "break-word",
//                             whiteSpace: "normal",
//                             textAlign: "start"
//                           }}
//                         >
//                      {items.folder_name ?`${items.folder_name}/${items.name}`:`${items.name}`}
//                         </h4>
//                         <Box sx={{ flexGrow: 1 }}>
//                           <Grid container spacing={3}>
//                             <Grid item xs={12} sm={6} md={4} lg={4}>
//                               <div
//                                 className={
//                                   selectedFile === index
//                                     ? "video-avtiveBtn"
//                                     : "video-InavtiveBtn"
//                                 }
//                               >
//                                 <Button>
//                                   {items.segment_completed
//                                     ? "Active"
//                                     : "InActive"}
//                                 </Button>
//                               </div>
//                             </Grid>
//                             {items.processing ? ( 
//                               <Grid item xs={12} sm={6} md={4} lg={4}>
//                                 <div className={"video-progress"}>
//                                   <Button>{"Progress"}</Button>
//                                 </div>
//                               </Grid>
//                             ) : (
//                               <>
//                                 {items.segment_completed && (
//                                   <Grid item xs={12} sm={6} md={4} lg={4}>
//                                     <div
//                                       className={
//                                         selectedFile === index
//                                           ? "video-avtiveBtn"
//                                           : "video-InavtiveBtn"
//                                       }
//                                     >
//                                       <Button>
//                                         {selectedFile === index
//                                           ? "Select"
//                                           : "UnSelect"}
//                                       </Button>
//                                     </div>
//                                   </Grid>
//                                 )}
//                               </>
//                             )}
//                           </Grid>
//                         </Box>
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={2} sm={2} md={2} sx={{ textAlign: "end" }}>
//                     <div>
//                       <Button
//                         aria-controls="simple-menu"
//                         aria-haspopup="true"
//                         onClick={(event) => handleClick(event, index)}
//                       >
//                         <ExpandMoreIcon className="expanticon" />
//                       </Button>
//                       <Menu
//                         id={`simple-menu-${index}`}
//                         anchorEl={anchorEl[index]}
//                         open={Boolean(anchorEl[index])}
//                         onClose={() => handleClose(index)}
//                       >
//                         {items.segment_completed && (
//                           <MenuItem
//                             onClick={() => {
//                               // alert(index)
//                               setSelectedFile(index)
//                               // console.log('selectedFile === i',selectedFile,index);
//                               handleMenuItemClick(items.id, index)}}
//                           >
//                             Select
//                           </MenuItem>
//                         )}

//                         {!items.segment_completed && (
//                           <MenuItem
//                             onClick={() => {
//                               if (items.processing,items.folder_name) {
//                                 handleClose(index);
//                               } else {
//                               }
//                               processVideoSegment(items.id,items.folder_name);
//                             }}
//                           >
//                             Process Video
//                           </MenuItem>
//                         )}
//                         <MenuItem
//                           onClick={() => {
//                             window.open(items.video, "_blank");
//                             handleClose(index);
//                           }}
//                         >
//                           View
//                         </MenuItem>

//                        {items.is_deleted&&<MenuItem onClick={() => deleteVideo(items.id, items.folder_name)}>
//                           Delete
//                         </MenuItem>} 
//                       </Menu>
//                     </div>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </CardContent>
//           </Card>
//         );
//       })}
//     </>
//   );
// };
// export default VideoList;














import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import Menu from "@mui/material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../events/style.css";
import { useSelector } from "react-redux";
import { videoDeleteFile, videoSegments } from "../../utils/apis/apis";

const VideoList = ({ videoList, videoType, setProcess, selectedValue,defultValueSelected }) => {
  const [anchorEl, setAnchorEl] = useState({});

  const handleClick = (event, itemId) => {
    setAnchorEl({ ...anchorEl, [itemId]: event.currentTarget });
  };

  const handleClose = (itemId) => {
    setAnchorEl({ ...anchorEl, [itemId]: null });
  };
  const [selectedOptionError, setSelectedOptionError] = React.useState("");
  const [selectedOption, setSelectedOption] = useState("select");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const sessionUser = useSelector((state) => state.session.user);
  const [progress, setProgress] = useState(100);
  const [showProgress, setShowProgress] = useState(false);
  const [uplodVideoLists, setUplodVideoLists] = useState([]);
  const [errorVideo, setErrorVideo] = useState("");
  const isVideoType = videoType == "Use Directory" ? "directory" : "uploade";
  const handleMenuItemClick = (id) => {
    setSelectedState(id);
    selectedValue({ videoType: isVideoType, videoId: id });
    handleClose(id);
  };
  useEffect(()=>{
    const aaaa = defultValueSelected?.videoId ? true :false

    if (defultValueSelected?.videoId) {
      setSelectedState(defultValueSelected?.videoId)
    } else {
      setSelectedState(null)
    }  
  },[])

  const processVideoSegment = async (id,folder_name) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", videoType);
    formData.append("user_id", sessionUser.id);

    formData.append("folder_name", folder_name);
    console.log("formData", id, videoType);
    handleClose(id);
    setTimeout(() => {
      setProcess(true);
    }, 2000);
    await videoSegments(formData)
      .then((val) => {
        setProcess(true);
        console.log("Upload successful", val);
      })
      .catch((err) => {
        console.error("Upload error:", err);
      });
  };
  const deleteVideo = async (id, folder_name) => {
    await videoDeleteFile(sessionUser.access, id, isVideoType, folder_name)
      .then((val) => {
        handleClose(id);
        setProcess(true);
      })
      .catch((err) => {
        console.error("Upload error:", err);
      });
  };
  return (
    <>
      {videoList.map((items, i) => {
        return (
          <Card sx={{ minWidth: 275, margin: "12px 0px", }} key={`${i}index`}>
            <CardContent sx={{paddingLeft: "25px"}}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={2} sm={2} md={2}>
                    <MusicVideoIcon className="music-icon" />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <Grid container className="videoname">
                      <Grid item xs={12} md={12}>
                        <h4
                          className="file-name"
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                            {items.folder_name ?`${items.folder_name}/${items.name}`:`${items.name}`}
                        </h4>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <div
                                className={
                                  selectedState === items.id
                                    ? "video-avtiveBtn"
                                    : "video-InavtiveBtn"
                                }
                              >
                                <Button>
                                  {items.segment_completed
                                    ? "Active"
                                    : "InActive"}
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              {items.segment_completed && (
                                <div
                                  className={
                                    selectedState === items.id
                                      ? "video-avtiveBtn"
                                      : "video-InavtiveBtn"
                                  }
                                >
                                  <Button>
                                    {selectedState === items.id
                                      ? "Select"
                                      : "UnSelect"}
                                  </Button>
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              {items.processing && (
                                <div className={"video-progress"}>
                                  <Button>{"Progress"}</Button>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} sx={{ textAlign: "end" }}>
                    <div>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleClick(event, items.id)}
                      >
                        <ExpandMoreIcon className="expanticon" />
                      </Button>
                      <Menu
                        id={`simple-menu-${items.id}`}
                        anchorEl={anchorEl[items.id]}
                        open={Boolean(anchorEl[items.id])}
                        onClose={() => handleClose(items.id)}
                      >
                        {items.segment_completed && (
                          <MenuItem
                            onClick={() => {
                              setSelectedFile(items.id)
                              handleMenuItemClick(items.id)
                            }}
                          >
                            Select
                          </MenuItem>
                        )}

                        {!items.segment_completed && (
                          <MenuItem
                            onClick={() => {
                              if (items.processing,items.folder_name) {
                                handleClose(items.id);
                              } else {
                              }
                              processVideoSegment(items.id,items.folder_name);
                            }}
                          >
                            Process Video
                          </MenuItem>
                        )}


                        <MenuItem
                          onClick={() => {
                            window.open(items.video, "_blank");
                            handleClose(items.id);
                          }}
                        >
                          View
                        </MenuItem>

                        {/* <MenuItem onClick={() => deleteVideo(items.id)}>
                          Delete
                        </MenuItem> */}

                        {items.is_deleted&&<MenuItem onClick={() => deleteVideo(items.id, items.folder_name)}>
                        Delete
                        </MenuItem> }
                      </Menu>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export default VideoList;