import axios from "axios";
import apis from "../../utils/constant/appConfig";
const BASE_URL = apis.BASE_URL;
const VIDEO_SEGMENT_URL = apis.VIDEO_SEGMENT_URL;
export const getGhostUsersList = async (token, page = 1) => {
  const url = `${BASE_URL}/api/all/ghost_user/?page=${page}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getGhostSelectedList = async (token, id) => {
  const url = `${BASE_URL}/api/all/ghost_user/?type=selected&webinar_id=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getAllghostuserapi = async (token, page = 1) => {
  const url = `${BASE_URL}/api/all/ghost_user/?type=all`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getNormalUserList = async (token, page = 1) => {
  const url = `${BASE_URL}/api/user_list/?page=${page}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSearchData = async (
  token,
  id,
  searchValue,
  filter,
  selectedOption,
  page
) => {
  let data = {
    search: searchValue,
    webinar_id: id,
  };

  let config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/api/webinar/search/?page=${page}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);

        console.log(json.data.total_pages_in_search, "json.data");
      })
      .catch((err) => {
        console.error("Error in API request:", err);
        reject(err);
      });
  });
};

export const getSearchNormalUsersData = async (token, searchVal, page) => {
  const url = `${BASE_URL}/api/user_search/?page=${page}&search=${searchVal}`;

  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSearchGhostUser = async (token, searchVal, page) => {
  const url = `${BASE_URL}/api/all/ghost_user/search?search=${searchVal}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFilterData = async (token, id, attendValue, filter, page) => {
  let data;

  if (attendValue === "YES") {
    data = {
      webinar_id: id,
      filter: attendValue,
    };
  } else if (attendValue === "NO") {
    data = {
      webinar_id: id,
      filter: attendValue,
    };
  } else if (
    attendValue === "0-25" ||
    attendValue === "25-50" ||
    attendValue === "50-75" ||
    attendValue === "75-100"
  ) {
    let dataSplit = attendValue.split("-");
    data = {
      webinar_id: Number(id),
      filter: {
        percentage: { min: Number(dataSplit[0]), max: Number(dataSplit[1]) },
      },
    };
  } else {
    data = {
      webinar_id: Number(id),
      filter: {
        tag: attendValue,
      },
    };
  }

  let config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/0/api/webinar/search/?page=${page}`,

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateWebinar = async (token, data) => {
  const url = `${BASE_URL}/api/webinar/update/`;
  let config = {
    url: url,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const createWebinar = async (token, data) => {
  const url = `${BASE_URL}/api/webinar/create/`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          alert("Your Session is Expired. Please log in");
          localStorage.clear();
          window.location.href = "/login"; 
        }
      });
  });
};

export const uploadVideoFile = async (token, data, onUploadProgress) => {
  const url = `${BASE_URL}/api/webinar/video/upload/`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
    onUploadProgress,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUploadVideoList = async (token) => {
  const url = `${BASE_URL}/api/webinar/video/list/`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const guestUserAttendees = async (data) => {
  const url = `${BASE_URL}/webinar/guest/attendees/`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const changePassword = async (token, data) => {
  const url = `${BASE_URL}/api/change-password/`;
  let config = {
    url: url,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const profileUpdate = async (token, data) => {
  const url = `${BASE_URL}/api/profile/update/`;
  let config = {
    url: url,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getuserDeta = async (token) => {
  const url = `${BASE_URL}/api/user/data`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const chatReport = async (token, id) => {
  const url = `${BASE_URL}/api/chatbot/list/?webinar_id=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const webAnalytics = async (token, id, page = 1) => {
  const url = `${BASE_URL}/webinar/analytics/registration_data?page=${page}&webinar_id=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const webRegistrantsExcel = async (token, id, page = 1) => {
  const url = `${BASE_URL}/webinar/analytics/registration_data?webinar_id=${id}&all_registration_data=ture`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const webAnalyticsSession = async (token, id, page = 1) => {
  const url = `${BASE_URL}/webinar/analytics/attendee_data?page=${page}&webinar_id=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
        console.log("configconfigconfig", json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSessionExcel = async (token, id, page = 1) => {
  const url = `${BASE_URL}/webinar/analytics/attendee_data?webinar_id=${id}&all_session_data=ture`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const logOutUser = async (token, data) => {
  const url = `${BASE_URL}/logout`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// user Login

export const loginUser = async (data) => {
  const url = `${BASE_URL}/api/login`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const UserSignUp = async (data) => {
  const url = `${BASE_URL}/api/user/register/`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAddQuestions = async (token, data) => {
  const url = `${BASE_URL}/add/ghost_user/question`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAddAnswer = async (token, data) => {
  const url = `${BASE_URL}/add/ghost_user/answer`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteAnswerList = async (token, id) => {
  const url = `${BASE_URL}/api/ghost/answer_delete?answer_id=${id}`;
  let config = {
    url: url,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteQuestionsAnswerList = async (token, id) => {
  const url = `${BASE_URL}/api/ghost/question_delete?question_id=${id}`;
  let config = {
    url: url,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getQuesAndAnsList = async (token, id) => {
  const url = `${BASE_URL}/api/ghost_user/question_answer?webinar_id=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const videoSegments = async (data) => {
  const url = `${VIDEO_SEGMENT_URL}/api/director/segss`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getVideoHlsPath = async (id) => {
  const url = `${BASE_URL}/api/webinar/live_webinar_details/${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDirectoryVideoList = async (token) => {
  const url = `${BASE_URL}/api/directory/video`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const videoDeleteFile = async (token, id, type, folder_name) => {
  const url = `${BASE_URL}/api/video/delete/?id=${id}&type=${type}&folder_name=${folder_name}`;
  let config = {
    url: url,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const webinarList = async (token, id) => {
  const url = `${BASE_URL}/api/webinar/list/?type=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          alert("Your Session is Expired. Please log in");
          localStorage.clear();     
          window.location.href = "/login";
        }
      });
  });
};

export const deleteWebinars = async (token, id) => {
  const url = `${BASE_URL}/api/webinar/delete/?webinar_id=${id}`;
  let config = {
    url: url,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEmailRecoverPassword = async (data) => {
  const url = `${BASE_URL}/send-reset-password-email/`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetPasswordByEmail = async (userId, userToken, data) => {
  const url = `${BASE_URL}/reset-password/${userId}/${userToken}`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const webinarUpdateData = async (token, id) => {
  const url = `${BASE_URL}/api/webinar/detail/?webinar_id=${id}`;
  let config = {
    url: url,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDummyUser = async (token, data, id) => {
  const url = `${BASE_URL}/api/add/ghost_user?id=${id}`;
  let config = {
    url: url,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateNormalUser = async (token, data, id) => {
  const url = `${BASE_URL}/api/normaluser/update/${id}`;
  let config = {
    url: url,
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  console.log(data, "ssssssssssssssssssssssssss", config);
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const creteDummyUser = async (token, data) => {
  const url = `${BASE_URL}/api/add/ghost_user`;
  let config = {
    url: url,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((json) => {
        resolve(json.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
