import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import DetailsIcon from "@mui/icons-material/Details";
import VideocamIcon from "@mui/icons-material/Videocam";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import MoreIcon from "@mui/icons-material/More";
import { Avatar, Button, Divider } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import Sheet from "@mui/joy/Sheet";
import Grid from "@mui/joy/Grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "../events/style.css";
import Layout from "../common/layout";
import EventGeneralInfo from "./EventGeneralInfo";
import { styled } from "@mui/joy";
import EventVideo from "./EventVideo";
import EventsLive from "./EventsLive";
import EventsOthers from "./EventsOthers";
import EventsQueAns from "./EventsQueAns";
import colors from "../../utils/constant/colors";
import Header from "../common/layout/Header";
import { Formik } from "formik";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  createWebinar,
  updateWebinar,
  uploadVideoFile,
} from "../../utils/apis/apis";
import { useNavigate } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
import EventsGhostUsers from "./EventsGhostUsers";
import GroupAdd from "@mui/icons-material/GroupAdd";
const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.level1 : "#fff",
  ...theme.typography["body-sm"],
  textAlign: "center",
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function EventSideNav() {
  let reloadPage = true;
  const [value, setValue] = React.useState(0);
  const [option, setOption] = React.useState("select");
  const [optionTime, setOptionTime] = React.useState(false);
  const [showForm,setShowForm] = React.useState(true);
   const [createWebinarId, setCreateWebinarId] = React.useState("");
    
  const webinarId = createWebinarId
    ? createWebinarId
    : localStorage.getItem("webinarId");
  const sessionUser = useSelector((state) => state.session.user);
  const [progress, setProgress] = React.useState(0);
  const navigate = useNavigate();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleNextClick = () => {
    setValue(value + 1);
  };
  const handlePrevClick = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };
  const handleCreateClick = () => {
    navigate(routerPath.home);
  };
  const initialValues = {
    name: "",
    title: "",
    select: "",
    urlPath: "",
    directory: "",
    directoryAndUploadedVideoData: "",
    uploadvideo: "",
    toggle_question: false,
    toggle_live_chat: false,
    schedule_time: null,
    webinarScheduleDMW: {},
    selected_ghost_user:[],
    resolution_status:[],
   
  };
  const validationSchema = [
    Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "min 3 characters")
        .required("Please enter Nname"),
      title: Yup.string()
        .trim()
        .required("Please enter title")
        .min(3, "minimum 3 characters"),
    }),
    Yup.object({
      select:
        option === "select"
          ? Yup.string().required("Please select any one")
          : null,
      urlPath:
        option === "urlPath"
          ? Yup.string().trim().required("Please enter url")
          : null,
      uploadvideo:
        option === "uploadvideo"
          ? Yup.string().required("Please select uploaded video")
          : null,
      directory:
        option === "directory"
          ? Yup.string().required("Please select directory video")
          : null,
    }),
    Yup.object({
      schedule_time:
        optionTime === true
          ? Yup.string().required("Please select  date and time")
          : null,
      webinarScheduleDMW: Yup.object({
        daily: Yup.bool(),
        weekly: Yup.bool(),
        monthly: Yup.bool(),
      }).test("at-least-one", "Select at least one option", (schedule_time) =>
        Yup.date().required("Please select a valid date and time")
      ),
    }),
  ];
  const [receivedIds, setReceivedIds] = React.useState([]);
  const handleChildData = (data) => {
    setReceivedIds(data);
  };
  const submits = async (val) => {
    if (value === 0) {
      try {
        const formData = {
          webinar_id: webinarId,
          name: val.name,
          title: val.title,
        };
        await updateWebinar(sessionUser.access, formData)
          .then((val) => {
            handleNextClick();
          })
          .catch((err) => {
            console.error("error:", err);
          });

        handleNextClick();
      } catch (error) {
        console.error("Error sending data:", error);
      }
    } else if (value === 1) {
      try {
        const formData = {
          webinar_id: webinarId,
          video_link: val.urlPath,
          video_type: val.urlPath
            ? "link"
            : val.directoryAndUploadedVideoData.videoType,
          video_file: val.directoryAndUploadedVideoData.videoId || "",
  
        };
        await updateWebinar(sessionUser.access, formData)
          .then((val) => {
            handleNextClick();
            console.log('formDataformData',formData);
          })
          .catch((err) => {
            console.error("error:", err);
          });
      } catch (error) {
        navigate(routerPath.home);
        console.error("Error sending data:", error);
      }
    } else if (value === 2 && (val.schedule_time || val.webinarScheduleDMW ) ) {
      if (val.webinarScheduleDMW && val.schedule_time == "") {
        setOptionTime(true);
      } else {
        setOptionTime(false);
      }
      try {
        await validationSchema[value].validate(val, { abortEarly: false });
        const resolutionStatus = val.resolution_status.length ? JSON.stringify(val.resolution_status) : "[0,1,2,3]";
        if (
          val.schedule_time ||
          val.webinarScheduleDMW.daily ||
          val.webinarScheduleDMW.weekly ||
          val.webinarScheduleDMW.monthly ||
        val.resolution_status
        ) {
          const formData = {
            webinar_id: webinarId,
            schedule_time: val.schedule_time ,
            is_daily: val.webinarScheduleDMW.daily ? true : false,
            is_weekly: val.webinarScheduleDMW.weekly ? true : false,
            is_monthly: val.webinarScheduleDMW.monthly ? true : false,
            resolution_status: resolutionStatus
          };
          console.log('formageta--------------','---------', formData.schedule_time,'---------');
          await updateWebinar(sessionUser.access, formData)
            .then((val) => {
              handleNextClick();
              console.log('formageta', formData);
            })
            .catch((err) => {
              console.error("error:", err);
            });
        } else {
          handleNextClick();
        }
      } catch (error) {
        console.error("Validation error:", error);
      }
    }
      else if (value === 3) {
      const selectedGhostUserString = JSON.stringify(receivedIds);
      try {
        const formData = {
        webinar_id: webinarId,
         selected_ghost_user:selectedGhostUserString
        };
        await updateWebinar(sessionUser.access, formData)
          .then((val) => {
            handleNextClick();
          })
          .catch((err) => {
            console.error("error:", err);
          });
      } catch (error) {
        navigate(routerPath.home);
        console.error("Error sending data:", error);
      }
    } else {
      handleNextClick();
    }
  };
  React.useEffect(() => {
    const dummyJson = {
      name: null,
      title: null,
      duration_hours: 0,
      duration_minute: 0,
      duration_second: 0,
      email_notification: [],
      text_notification: [],
      schedule_time: null,
      status: "active",
      selected_ghost_user:''
    };
    createWebinar(sessionUser.access, dummyJson)
    .then((val) => {
      const jsonData = val.data;
      const newWebinarId = jsonData.id;
      setCreateWebinarId(newWebinarId);
      localStorage.setItem("webinarId", JSON.stringify(newWebinarId));
      if (newWebinarId === undefined && reloadPage) {
        console.warn("webinarId is undefined. Reloading the page...");
        window.location.reload();
        reloadPage = false; 
      }
    })
    .catch((err) => {
      console.error("Upload error:", err);
    });
  }, []);
  return (
    <>
      <Layout showSideBar active={1}>
      <Helmet>
        <title>Letswebinar | events</title>
      </Helmet>
        <div style={{ display: "flex", overflow: "auto" }}>
          <Grid style={{ overflow: "auto" }}>
            <Grid xs={6} md={12} style={{ overflow: "auto" }}>
              <Item>
                <Box
                  sx={{ bgcolor: "background.paper" }}
                  className="display-tabs  tab-container"
                >
                  <div
                    className="show-tabs "
                    style={{ borderRight: "1px solid lightgrey;" }}
                  >
                    <div>
                      <CalendarMonthIcon className="sidenav-icon" />
                    </div>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChangeTab}
                      className="tab-content  tabspanel"
                      aria-label="Vertical tabs example"
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                        width: "100%"
                      }}
                      style={{ width: "100%!important"}}
                    >
                      <Tab
                        sx={{ paddingRight: "0rem" }}
                        icon={
                          <Avatar
                            style={{ marginRight: "16px", marginLeft: "-34px" }}
                            sx={{ bgcolor: value === 0 ? colors.primary : " " }}
                          >
                            <DetailsIcon />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="Details"
                        disabled={value === 0? false : true}
                 
                        {...a11yProps(0)}
                        tabindicatorprops={{
                          sx: {
                            backgroundColor: "red",
                          },
                        }}
                      />
                      <Tab
                        sx={{
                          borderTop: "1px solid lightgrey;",
                          width: "100%",
                        }}
                        icon={
                          <Avatar
                            style={{ marginRight: "16px", marginLeft: "-34px" }}
                            sx={{ bgcolor: value === 1 ? colors.primary : " " }}
                          >
                            <VideocamIcon />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="Video"
                        disabled={value === 1 ? false : true}
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{
                          borderTop: "1px solid lightgrey;",
                          width: "100%",
                          paddingRight: "2rem",
                        }}
                        icon={
                          <Avatar
                            style={{ marginRight: "16px", marginLeft: "-34px" }}
                            sx={{ bgcolor: value === 2 ? colors.primary : " " }}
                          >
                            <LiveTvIcon />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="Live"
                        disabled={value === 2 ? false : true}
                        {...a11yProps(2)}
                      />
                        <Tab
                        sx={{
                          borderTop: "1px solid lightgrey;",
                          width: "100%;",
                          paddingRight: "2rem",
                          textAlign:'start'
                        }}
                        icon={
                          <Avatar
                          className="iconGhost"
                            sx={{ bgcolor: value === 3 ? colors.primary : " " }}
                          >
                            <GroupAdd />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="Ghost Users"
                        disabled={value === 3 ? false : true}
                        {...a11yProps(3)}
                      />
                      <Tab
                        sx={{
                          borderTop: "1px solid lightgrey;",
                          width: "100%;",
                          paddingRight: "2rem",
                          textAlign:'start'
                        }}
                        icon={
                          <Avatar
                          className="iconGhost1"
                           
                            sx={{ bgcolor: value === 4 ? colors.primary : " " }}
                          >
                            <QuizIcon />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="Ques  and Ans"
                        disabled={value === 4 ? false : true}
                        {...a11yProps(4)}
                      />
                    </Tabs>
                  </div>

                  <div style={{ overflow: "auto" }}>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema[value]}
                      onSubmit={submits}
                      onReset={initialValues}
                    >
                      {({
                        values,
                        errors,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                      }) => {
                        console.log('setFieldValuesetFieldValue',setFieldValue);
                        const { name, title } = values;
                        return (
                          <>
                            <div
                              className="container-events"
                             
                            >
                              <TabPanel value={value} index={0}>
                                <EventGeneralInfo
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  webinarId={webinarId}
                                />
                              </TabPanel>
                              <TabPanel value={value} index={1}>
                                <EventVideo
                                  webinarId={webinarId}
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  option={option}
                                  setOption={(op) => {
                                    setOption(op);
                                  }}
                                  progress={progress}
                                />
                              </TabPanel>
                              <TabPanel value={value} index={2}>
                                <EventsLive
                                  webinarId={webinarId}
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  setShowForm={setShowForm}
                                  showForm={showForm}
                                  setOption={(op) => {
                                    setOption(op);
                                  }}
                                />
                              </TabPanel>
                              <TabPanel value={value} index={3} >
                                <EventsGhostUsers webinarId={webinarId}    values={values}  setFieldValue={setFieldValue}
                                  errors={errors} onChildData={handleChildData}  setOption={(op) => {
                                    setOption(op);
                                  }} />
                                
                              </TabPanel>
                              <TabPanel value={value} index={4}>
                                <EventsQueAns webinarId={webinarId} />
                              </TabPanel>
                              <Box className="btnprenxt">
                                <Button
                                  variant="contained"
                                  className="btn-prev"
                                  onClick={handlePrevClick}
                                  disabled={value === 0}
                                >
                                  Prev
                                </Button>
                                <Button
                                  variant="contained"
                                  className={"jgugf"}
                                  onClick={
                                    value === 4
                                      ? handleCreateClick
                                      : handleSubmit
                                  }
                                >
                                  {value === 4 ? "Create" : "Next"}
                                </Button>
                              </Box>
                        
                            </div>
                          </>
                        );
                      }}
                    </Formik>
                  </div>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </>
  );
}
 