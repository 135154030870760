import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Layout from "../common/layout";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as sessionActions from "../../store/session";
import { getVideoHlsPath, guestUserAttendees } from "../../utils/apis/apis";
import ReCAPTCHA from "react-google-recaptcha";
import appConfig from "../../utils/constant/appConfig";
import constvalue from "../../utils/constant/constvalue";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const useQuery = () => {
  return new URLSearchParams(window.location.search);
};
const WebinarJoin = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { webinarId } = useParams();
  const query = useQuery();
  const queryParams = new URLSearchParams(window.location.search);
  const [getTitle,setGetTtitle] = useState('');
  const nameParam = queryParams.get("name");
  const mobileParam = queryParams.get("mobile");
  const emailParam = queryParams.get("email");
  const tagsParam = queryParams.get("tag");
  const tagsUserParam = queryParams.get("tag_user");
  const tagsCompanyParam = queryParams.get("tag_company");
  const tagsCustomParam = queryParams.get("tag_custom");
  const tagsCustom2Param = queryParams.get("tag_custom2");
  const tagsSourceParam = queryParams.get("tag_source");
  const tagsMediumParam = queryParams.get("tag_medium");
  const tagsCampaignParam = queryParams.get("tag_campaign");
  const tagsContentParam = queryParams.get("tag_content");
  const tagsTermsParam = queryParams.get("tag_term");
  const locationss = useLocation();
  const isVideoPage1 = locationss.pathname === `/webinarJoin/${webinarId}`;
  const [isLeave, setIsLeave] = React.useState(isVideoPage1);
  const formik = useFormik(
    {
      initialValues: {
        first_name: nameParam || "",
        email: emailParam || "",
        phone_number: mobileParam || "",
        tags: {
          tag: tagsParam || "",
          tag_user: tagsUserParam?.toString() || "",
          tag_company: tagsCompanyParam?.toString() || "",
          tag_custom: tagsCustomParam?.toString() || "",
          tag_custom2: tagsCustom2Param?.toString() || "",
          tag_source: tagsSourceParam?.toString() || "",
          tag_medium: tagsMediumParam?.toString() || "",
          tag_campaign: tagsCampaignParam?.toString() || "",
          tag_content: tagsContentParam?.toString() || "",
          tag_term: tagsTermsParam?.toString() || "",
        },
      },
      validationSchema: Yup.object({
        first_name: Yup.string().required("You must enter full name"),
        phone_number: Yup.string()
          .matches(/^[0-9]+$/, "Phone number must contain only numeric digits")
          .required("You must enter a valid phone number")
          .max(10, "10 digits required."),
      }),
      onSubmit: async (values, { resetForm }) => {
        try {
          setLoading(true);
          const tags = values.tags;
          const tagsDict = {
            tag: tagsParam || "",
            tag_user: tagsUserParam?.toString() || "",
            tag_company: tagsCompanyParam?.toString() || "",
            tag_custom: tagsCustomParam?.toString() || "",
            tag_custom2: tagsCustom2Param?.toString() || "",
            tag_source: tagsSourceParam?.toString() || "",
            tag_medium: tagsMediumParam?.toString() || "",
            tag_campaign: tagsCampaignParam?.toString() || "",
            tag_content: tagsContentParam?.toString() || "",
            tag_term: tagsTermsParam?.toString() || "",
          };

          const formData = {
            webinar: webinarId,
            full_name: values.first_name,
            guest_email: values.email,
            guest_phone: values.phone_number,
            tags: tagsDict,
          };
          const value = await guestUserAttendees(formData);
          const userAllData = value.data;
          if (value.status === "success") {
            localStorage.setItem("user", JSON.stringify(userAllData));
            dispatch(sessionActions.setUser(userAllData));
            toast.success(value.message);
            setLoading(false);
            navigate(`/webinarView/${webinarId}`);
          } else {
            toast.error(value.message);
            setLoading(false);
          }
        } catch (error) {
          console.error("API request error:", error);
          toast.error("Error occurred while submitting the form.");
          setLoading(false);
        } finally {
          setLoading(false);
        }
      },
    },
    []
  );
  useEffect(() => {
    if (formik.values.first_name && formik.values.email && formik.values.phone_number) {
      formik.handleSubmit();
    } else {
      setLoading(false);
    }
  }, []);
  const directCallApi = () => {};
    useEffect(() => {
      videoURLFile();
    }, []);
    const videoURLFile = async () => {
      await getVideoHlsPath(webinarId)
        .then((val) => {
            setGetTtitle(val.name)
            
        })
        .catch((err) => {
        
        });
    };
  
  return (
    <div>
     <Helmet>
        <title>Letswebinar | {getTitle}</title>
      </Helmet>
    <Layout>
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            backgroundColor: "background.paper",
            flex: "1 1 auto",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            margin: "3rem 0rem 0rem 0rem",
          }}
        >
          <Box
            sx={{
              px: 3,
              py: "100px",
            }}
            className="container-joinform"
          >
            <div>
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Typography
                  variant="h4"
                  className="heading-form"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Webinar Registration
                </Typography>
                <Typography
                  style={{ textAlign: "center" }}
                  color="text.secondary"
                  variant="body2"
                  className="m-5 sub-heading-form"
                >
                  {getTitle}
                </Typography>
                <Typography
                  className="errorMessage"
                  sx={{ color: "red", textAlign: "center" }}
                ></Typography>
              </Stack>
              <form noValidate onSubmit={(val) => formik.handleSubmit(val)}>
                <Stack spacing={2}>
                  <div className="text-field w-textField">
                    <TextField
                      error={
                        !!(
                          formik.touched.first_name && formik.errors.first_name
                        )
                      }
                      fullWidth
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                      id="standard-textarea"
                      label="Full Name"
                      placeholder=" Enter Full Name"
                      variant="standard"
                      type="text"
                      name="first_name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Stack>
                <div className="text-field  m-17" style={{ marginTop: "1rem" }}>
                  <TextField
                    id="standard-basic"
                    label="Phone no"
                    variant="standard"
                    type="tel"
                    error={
                      !!(
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      )
                    }
                    fullWidth
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                    name="phone_number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneAndroidIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </div>
                <Stack spacing={3} className="m-17">
                  <div className="text-field" style={{ marginTop: "1rem" }}>
                    <TextField
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      id="standard-textarea"
                      label="Email"
                      placeholder="Enter Email"
                      variant="standard"
                      type="email"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Stack>
                <Stack spacing={2} className="m-17" style={{ width: "33px" }}>
                  <div
                    className="text-field w-textField"
                    style={{ marginTop: "1rem" }}
                  >
                    <ReCAPTCHA
                      className="g-recaptcha "
                      sitekey={appConfig.RE_CAPTURE_TOKEN}
                      onChange={(val) => {
                        formik.setFieldValue("recaptcha_response", val);
                      }}
                    />
                  </div>
                  <div style={{ width: "200px" }}>
                    {formik.errors.recaptcha_response && (
                      <Typography
                        color="error"
                        sx={{ mt: 3, width: "100%" }}
                        variant="body2"
                      >
                        {formik.errors.recaptcha_response}{" "}
                      </Typography>
                    )}
                  </div>
                </Stack>

                {formik.errors.submit && (
                  <Typography color="error" sx={{ mt: 3 }} variant="body2">
                    {formik.errors.submit}
                  </Typography>
                )}
                <div className="bg-btncolor">
                  <Button
                    fullWidth
                    size="large"
                    sx={{
                      mt: 3,
                      borderRadius: "8px",
                    }}
                    type="submit"
                    variant="contained"
                    className="bg-btncolor"
                  >
                    Join Now
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </Box>
      )}
    </Layout>
    </div>
  );
};

export default WebinarJoin;