import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ChatIcon from "@mui/icons-material/Chat";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import routerPath from "../../utils/router/routerPath";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { deleteWebinars, webinarList } from "../../utils/apis/apis";
import { setDesc, setTitle } from "../../store/session";

const itemStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "4px 0",
};
const Lists = ({ type }) => {
  const sessionUser = useSelector((state) => state.session.user);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };
  const deleteWebinar = async (id) => {
    handleClose();
    setLoading(true);
    try {
      await deleteWebinars(sessionUser.access, id)
        .then((val) => {
          getWebinarList();
        })
        .catch((err) => {
          console.error("Upload error:", err);
        });
    } catch (error) {
      navigate(routerPath.home);
      console.error(`Error deleting webinar with ID ${id}:`, error);
    }
  };
  const getWebinarList = async () => {
    setLoading(true);
    try {
      await webinarList(sessionUser.access, type)
        .then((val) => {
          const jsonData = val.data;
          setData(jsonData);
          setLoading(false);
        })
        .catch((err) => {
          console.error("error:", err);
        });
    } catch (error) {}
  };
  useEffect(() => {
    getWebinarList();
  }, [type]);
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy URL to clipboard: ", error);
      });
  };
  const copyUrl = (url) => {
    copyToClipboard(url);
  };
  const dispatch = useDispatch();
  const handleMenuItemClick = (webinarId, webinarTitle, webinarDesc) => {
    console.warn('webinarTitlewebinarTitle',webinarTitle);
    navigate(`${routerPath.webinarView}/${webinarId}`);
   
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }} >
        {data.length == 0 && loading == false ? (
          <div style={{ fontSize: 30, fontWeight: "bold" ,padding: "37px"}}>
            {"Not any Webinar"}
          </div>
        ) : null}
        {data &&
          data?.map((item, index) => (
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "10px",
              
              }} className="padding-list"
              key={index}
            >
              <Grid item lg={6} xs={5} md={6} sm={6}>         
             <div className='alignheading'>
             <Typography
                  variant="span"
                  gutterBottom
                  className="heading-details "
                  sx={{ textTransform: "capitalize" }}
                >
                  {item.title}
                </Typography>
             </div>
              </Grid>
              <Grid item lg={3} xs={3.5} md={3} sm={3}>
                <div className="btn-status">
                  <Button
                    variant="contained"
                    sx={{
                      width: "100px",
                      backgroundColor: "var(--themecolor)",
                      "&:hover": {
                        backgroundColor: "#a572a7",
                      },
                    }}
                  >
                    {item.status}
                  </Button>
                </div>
              </Grid>
              <Grid item lg={3} xs={3.5} md={3} sm={3} style={{textAlign:'end'}}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, item)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedItem === item}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      copyUrl(item.webinar_share_link , index);
                      handleClose();
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                        marginBottom: "-1rem",
                      }}
                    >
                      <ContentCopyIcon />
                      <h4 style={{ marginLeft: "5px" }}>Copy URL</h4>
                    </div>
                  </MenuItem>
                  <MenuItem
            onClick={() => handleMenuItemClick(item.id, item.title,item.name)
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center ",
                alignItems: "center",
                marginBottom: "-1rem",
              }}
            >
              <RemoveRedEyeIcon />
              <h4 style={{ marginLeft: "5px" }}>View Webinar</h4>
            </div>
          </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(`${routerPath.webinarEdit}/${item.id}`)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                        marginBottom: "-1rem",
                      }}
                    >
                      <EditIcon />
                      <h4 style={{ marginLeft: "5px" }}>Edit</h4>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={() => deleteWebinar(item.id)}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                        marginBottom: "-1rem",
                      }}
                    >
                      <DeleteIcon />
                      <h4 style={{ marginLeft: "5px" }}>Delete</h4>
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(`${routerPath.webinarAnalytics}/${item.id}`)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                        marginBottom: "-1rem",
                      }}
                    >
                      <ContentPasteIcon />
                      <h4 style={{ marginLeft: "5px" }}>Webinar Analytics</h4>
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(`${routerPath.chatReport}/${item.id}`)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center ",
                        alignItems: "center",
                      }}
                    >
                      <ChatIcon />
                      <h4 style={{ marginLeft: "5px" }}>Chat Report</h4>
                    </div>
                  </MenuItem>
                </Menu>
              </Grid>
              <Divider sx={{ width: "100%", marginTop: "12px" }} />
            </Grid>
          ))}
      </Box>

      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Lists;
