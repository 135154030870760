import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import "./style.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Layout from "../common/layout";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import {
  chatReport,
  creteDummyUser,
  getGhostUsersList,
  getSearchGhostUser,
  updateDummyUser,
} from "../../utils/apis/apis";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import colors from "../../utils/constant/colors";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { IoSearchSharp } from "react-icons/io5";
const style = {
  marginTop: "20px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please Enter Email"),
  firstName: Yup.string().required("Please Enter First Name"),
  lastName: Yup.string().required("Please Enter Last Name"),
  phone: Yup.string().required("Please Enter Phone Number"),
  password: Yup.string().required("Please Enter Password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please Enter Confirm Password"),
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function DummyUsers() {
  const { webinarId = 71 } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  const [chatData, setChatData] = React.useState([]);
  const [totalData, setTotalData] = React.useState(0);
  const [totalDataSearch, setTotalDataSearch] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);
  const [showPage, setShowPage] = useState();
  const [showPageSea, setShowPageSea] = useState();
  const [loading, setLoading] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    edit: "",
    id: "",
  });
  const [switches, setSwitches] = React.useState({});
  const [searchName, setSearchName] = useState("");
  const [searchVal, setSearchVal] = useState([]);
  const [page, setPage] = React.useState(1);
  const [page3, setPage3] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const handleSwitchToggle = (switchName) => {
    console.log(switchName);
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [switchName]: !prevSwitches[switchName],
    }));
  };

  const handleChange3 = (event, page) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    setPage3(page);
    if (page === totalPages) {
      getData(page + 1);
    }
  };
  const getData = async (page) => {
    setLoading(true);
    try {
      const nextPageToFetch = nextPage || page;
      const value = await getGhostUsersList(
        sessionUser.access,
        nextPageToFetch
      );
      setLoading(false);
      setChatData(value.data);
      setTotalData(value.total_ghost_user_length);
      setSearchVisible(false);
      setTotalRows(value.total_pages);
    } catch (error) {}
  };
  useEffect(() => {
    getData(page3);
  }, [page3]);

  const handleSearchPagination = (event, page) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    setPage3(page);
    if (page === totalPages) {
      handleSearch(page + 1);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const addUsers = () => {
    if (formData.first_name == "") {
      toast.error("Enter First name");
    } else if (formData.last_name == "") {
      toast.error("Enter Last name");
    } else {
      if (formData.edit == "add") {
        createUser();
      } else {
        updateUser();
      }
      setShow(!show);
    }
  };
  const updateUser = async (id) => {
    const updateData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
    };
    try {
      const value = await updateDummyUser(
        sessionUser.access,
        updateData,
        formData.id
      );
      if (value.status === "success") {
        toast.success(value.message);
        getData();
      } else {
        toast.error(value.message);
      }
    } catch (err) {
      toast.error("Try after some time?");
    }
  };
  const createUser = async (id) => {
    const creteData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
    };
    try {
      const value = await creteDummyUser(sessionUser.access, creteData);
      if (value.status === "success") {
        toast.success(value.message);
        getData();
      } else {
        toast.error(value.message);
      }
    } catch (err) {
      toast.error("Try after some time?");
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      confirmPassword: "",
      activateUser: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const handleSearch = async (e) => {
    try {
      const inputVal = e.target.value;
      setSearchName(inputVal);

      if (inputVal.trim() === "") {
        setShowPage(true);
        setShowPageSea(false);
        setSearchVisible(true);
        getData();
      } else {
        setShowPage(false);
        setShowPageSea(true);
        setSearchVisible(true);
        const nextPageToFetch = nextPage || page;
        const searchResults = await getSearchGhostUser(
          sessionUser.access,
          inputVal,
          nextPageToFetch
        );
        if (
          searchResults &&
          searchResults.status === "success" &&
          searchResults.data === "no data found"
        ) {
          setChatData([]);
          setTotalRows(0);
          setTotalDataSearch(0);
          console.log("No data found for the search.");
        } else if (searchResults && searchResults.data.length > 0) {
          setChatData(searchResults.data);
          setTotalRows(searchResults.total_pages);
          setTotalDataSearch(searchResults.total_search_ghost_user);
          console.log("11111", searchResults.total_search_ghost_user);
        } else {
        }
      }
    } catch (error) {
      console.error("Error during search:", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const paginatedData = chatData
    .filter((row) => {
      const fullName = `${row.first_name} ${row.last_name}`;
      return fullName.toLowerCase().includes(searchName.toLowerCase());
    })
    .slice(startIndex, endIndex);
  return (
    <>
      <Helmet>
        <title>Letswebinar | webinarGhostUser</title>
      </Helmet>
      <Grid>
        <Layout showSideBar active={2}>
          <div className="">
            <Grid className="gridPadding">
              <div className="heading-chatreport ghostusercontainer">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    textAlign: "start",
                    fontSize: "22",
                    fontWeight: "800",
                  }}
                >
                  {searchVisible ? (
                    <p> Ghost Users List ({totalDataSearch})</p>
                  ) : (
                    <p>Ghost Users List ({totalData})</p>
                  )}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: " space-between",
                  }}
                >
                  <div>
                    <div className="searchUser">
                      <TextField
                        id="outlined-basic"
                        placeholder="Search..."
                        variant="outlined"
                        value={searchName}
                        onChange={handleSearch}
                        sx={{ "& input": { height: "7px" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IoSearchSharp style={{ fontSize: "22px" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="btn-prev createUserBtn"
                    sx={{ backgroundColor: colors.primary }}
                    onClick={() => {
                      setFormData({
                        first_name: "",
                        last_name: "",
                        edit: "add",
                      });

                      setShow(!show);
                    }}
                  >
                    Add Ghost User
                  </Button>
                </div>
              </div>
              <TableContainer
                className="tablecontainer table-w"
                sx={{ border: "none" }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        S.no
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Date and Time
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        left: "50%",
                        marginTop: "4rem",
                        display: "grid",
                      }}
                    />
                  ) : (
                    <TableBody>
                      {paginatedData?.length > 0 ? (
                        paginatedData
                          .filter((row) => {
                            const fullName = `${row.first_name} ${row.last_name}`;
                            return fullName
                              .toLowerCase()
                              .includes(searchName.toLowerCase());
                          })
                          .map(
                            (row, i) =>
                              btnVisible && (
                                <TableRow key={`${i}chatData`}>
                                  <TableCell
                                    className="table-celltr"
                                    component="th"
                                    scope="row"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                      border:
                                        "1.94px solid rgb(117 114 116 / 31%) !important;",
                                    }}
                                  >
                                    {(page3 - 1) * rowsPerPage + i + 1}
                                  </TableCell>
                                  <TableCell
                                    className="table-celltr"
                                    component="th"
                                    scope="row"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                      border:
                                        "1.94px solid rgb(117 114 116 / 31%) !important;",
                                    }}
                                  >
                                    {row.first_name + " " + row.last_name}
                                  </TableCell>
                                  <TableCell
                                    className="table-celltr"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                      border:
                                        "1.94px solid rgb(117 114 116 / 31%) !important;",
                                    }}
                                  >
                                    {row.registration_date}
                                  </TableCell>
                                  <TableCell
                                    className="table-celltr"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                      border:
                                        "1.94px solid rgb(117 114 116 / 31%) !important;",
                                    }}
                                  >
                                    <Edit
                                      onClick={() => {
                                        setFormData({
                                          first_name: row.first_name,
                                          last_name: row.last_name,
                                          id: row.id,
                                          edit: "edit",
                                        });

                                        setShow(!show);
                                      }}
                                      sx={{}}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                          )
                      ) : (
                        <TableRow style={{ width: "100%" }}>
                          <TableCell
                            colSpan={4}
                            style={{ textAlign: "center" }}
                          >
                            No data found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Stack
                spacing={2}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={totalRows}
                  page={page3}
                  onChange={handleSearchPagination}
                  variant="outlined"
                  shape="rounded"
                  sx={{ color: "#6b59cc" }}
                />
              </Stack>
            </Grid>
            <Modal
              open={show}
              onClose={() => {
                setShow(!show);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  ...style,
                  maxHeight: "80vh",
                  overflowY: "auto", 
                }}
                className="container-addQue"
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    textAlign: "start",
                    fontSize: "22",
                    fontWeight: "800",
                  }}
                >
                  Add Guest User's
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  className="ques-field"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />

                <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    multiline
                    maxRows={4}
                    className="ques-field"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </Box>
                <div>
                  <div className="btn-Addque">
                    <Button
                      variant="contained"
                      onClick={() => {
                        addUsers();
                      }}
                    >
                      {formData.edit == "edit" ? "Update" : "Add"} User
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </Layout>
      </Grid>
    </>
  );
}
