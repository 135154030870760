import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
const participantsData = [
  {
    name: "Participant 1",
    avatar: "url_to_avatar_1.png",
  },
  {
    name: "Participant 2",
    avatar: "url_to_avatar_2.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "Participant 3",
    avatar: "url_to_avatar_3.png",
  },
  {
    name: "pratik 3",
    avatar: "url_to_avatar_3.png",
  },
];
const Participants = ({webinarParticepents}) => {
  return (
    <div
      style={{
      
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",   
        

      }}
      className="height-parti"
    >
      <div style={{ flex: "1",   }} > 
        <List className="particepent-container" style={{maxHeight:"733px"}}>
          {webinarParticepents.map((participant, index) => (
            <React.Fragment key={index}>
               <ListItem key={index+90}>
                <ListItemAvatar>
                <Avatar
                  alt={participant.attendee_name?.toUpperCase()}
                  src={participant.attendee_name}
                  sx={{ marginRight: "2px" }}
                />
                </ListItemAvatar>
                <ListItemText primary={participant.attendee_name} />
                <VideocamOffIcon style={{    color: "#bdbdbd",marginRight:'8px'}}/>
                <MicOffIcon style={{    color: "#bdbdbd"}}/>
              </ListItem>         
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Participants;
