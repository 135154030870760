import PasswordIcon from "@mui/icons-material/Password";
import MyDetails from "./MyDetails";
import ChangePassword from "./ChangePassword";
import "../setting/style.css";
import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Person2Icon from "@mui/icons-material/Person2";
import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import Layout from "../common/layout";
import colors from "../../utils/constant/colors";
import "../home/style.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function HomeTab() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Layout showSideBar active={1}>
        <Helmet>
          <title>Letswebinar | setting</title>
        </Helmet>
        <div style={{ display: "flex" }}>
          <Box
            sx={{
              bgcolor: "background.paper",
            }}
            className="height-setting"
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className="height-conset">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{ borderRight: "1px solid #c3c3c3" }}
                >
                  <div className="tabs-home ">
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                      className="tabs-homeschange"
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <Tab
                        className="tab-bar-event"
                        icon={
                          <Avatar
                            sx={{ bgcolor: value === 0 ? colors.primary : " " }}
                            className="pers2"
                          >
                            <Person2Icon />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="All Events"
                        {...a11yProps(0)}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      />
                      <Tab
                        className="tab-bar-pass"
                        style={{ textAlign: "start" }}
                        icon={
                          <Avatar
                            sx={{ bgcolor: value === 1 ? colors.primary : " " }}
                            className="pass1"
                          >
                            <PasswordIcon />
                          </Avatar>
                        }
                        iconPosition="start"
                        label="Change Password"
                        {...a11yProps(1)}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      />
                    </Tabs>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <div>
                    <div className="setting-tab-container">
                      <TabPanel value={value} index={0}>
                        <MyDetails />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <ChangePassword />
                      </TabPanel>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Layout>
    </>
  );
}
