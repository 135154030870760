import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./auth.css";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import Layout from "../common/layout";
import routerPath from "../../utils/router/routerPath";
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../store/session";
import { toast } from "react-toastify";
import { loginUser } from "../../utils/apis/apis";
import appConfig from "../../utils/constant/appConfig";
import { Helmet } from "react-helmet";
const Login = () => {
  const [errorMessage, setErrorMessage] = useState(" ");
  const sessionUser = useSelector((state) => state.session.user);
  const navigate = useNavigate();
  const [method, setMethod] = useState("email");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptcha_response: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("You must enter a valid email")
        .required("You must enter a email"),
      password: Yup.string()
        .required("Please enter your password.")
        .min(8, "Password is too short - should be 8 chars minimum."),
      recaptcha_response: Yup.string().required("Please verify yourself."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const formData = new FormData();
        formData.append("username", values.email);
        formData.append("password", values.password);
        formData.append("recaptcha_response", values.recaptcha_response);
        setLoading(true);
        await loginUser(formData)
          .then((value) => {
            if (value.status === "success") {
              const userAllData = value.data;
              localStorage.setItem("user", JSON.stringify(userAllData));
              dispatch(sessionActions.setUser(userAllData));
              setLoading(false);
              toast.success(value.message);
              navigate(routerPath.home);
            } else {
              helpers.setFieldValue("recaptcha_response", "");
              setLoading(false);
              recaptchaRef.current.reset();
              toast.error(value.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
            console.error("error", err);
          });
      } catch (error) {
        console.error("API request error:", error);
        toast.error("Something API Issue");
      }
    },
  });
  return (

    <Layout buttonName={"Sign up"}>
               <Helmet>
  <title>Letswebinar | login</title>
</Helmet>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0rem 0rem 0rem",
        }}
        className='height-auth'
      >
        <Box
          sx={{
            px: 3,
          }}
          className="box-form "
        >
          <div className="margin-box">
            <Stack spacing={1} sx={{ mb: 3 }} className="login-heading-stack">
              <div>
                <Typography
                  variant="h4"
                  className="heading-form"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Welcome to Letswebinar
                </Typography>
                <Typography
                  variant="h4"
                  className="heading-form"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Sign In to getting started.
                </Typography>
              </div>
              <Typography
                style={{ textAlign: "center" }}
                color="text.secondary"
                variant="body2"
                className="sub-heading-form"
              >
                Enter your details to proceed further
              </Typography>
              <Typography className="errorMessage">{errorMessage}</Typography>
            </Stack>

            {method === "email" && (
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  <div className="text-field">
                    <TextField
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      id="standard-textarea"
                      label="Email"
                      placeholder="Enter Email"
                      variant="standard"
                      type="email"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="text-field">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      name="password"
                      label="Password"
                      placeholder="Enter Password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityIcon
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Stack>

                <Stack spacing={2} className="m-17" style={{ width: "33px" }}>
                  <div
                    className="text-field w-textField"
                    style={{ marginTop: "1rem" }}
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      className="g-recaptcha "
                      sitekey={appConfig.RE_CAPTURE_TOKEN}
                      onChange={(val) => {
                        formik.setFieldValue("recaptcha_response", val);
                      }}
                    />
                  </div>
                  <div style={{ width: "200px" }}>
                    {formik.errors.recaptcha_response && (
                      <Typography
                        color="error"
                        sx={{ mt: 3, width: "100%" }}
                        variant="body2"
                      >
                        {formik.errors.recaptcha_response}{" "}
                      </Typography>
                    )}
                  </div>
                </Stack>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className="text-field m-17"
                >
                  <Grid item>
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Remember me"
                      className="remember-txt"
                    />
                    <span className="circle-remember"></span>
                  </Grid>
                  <Grid item>
                    <NavLink to={routerPath.recoverPassword} className="link">
                      <p>Recover password</p>
                    </NavLink>
                  </Grid>
                </Grid>
                <div className="bg-btncolor">
                  {loading ? (
                    <CircularProgress
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        display: "grid",
                      }}
                    />
                  ) : (
                    <Button
                      fullWidth
                      size="large"
                      sx={{
                        mt: 3,
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Sign In
                    </Button>
                  )}
                </div>
              </form>
            )}
            <NavLink className="link" to={routerPath.signup}>
              <p className="recover-pass" style={{ margin: "21px 0px" }}>
                Don't have an account? Create your account now!
              </p>
            </NavLink>
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default Login;
