import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import "../chatReport/style.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSessionExcel, webAnalyticsSession } from "../../utils/apis/apis";
import routerPath from "../../utils/router/routerPath";
export default function SessionTable({ getData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const param = useParams();
  const searchId = param.webinarId;
  const [inpse, setInsp] = React.useState("");
  const { webinarId } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  const [selectedPercentage, setSelectedPercentage] = React.useState("");
  const [sessionData, SetSessionData] = React.useState([]);
  const [searchName, setSearchName] = React.useState("");
  const rowsPerPage = 5;
  const rowsPerPageSessiion = 5;
  const [page3, setPage3] = React.useState(1);
  const [pageSess, setPageSess] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [showPage, setShowPage] = React.useState();
  const [showPageSea, setShowPageSea] = React.useState();
  const [pagesession, setPageSession] = React.useState(1);
  const [nextPageSession, setNextPageSession] = React.useState(null);
  const [totalData,setTotalData] = React.useState(0);
  const [totalRowssession, setTotalRowssession] = React.useState(0);
  const startIndex = (page3 - 1) * rowsPerPage;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = sessionData.slice(startIndex, endIndex);
  const paggggg = paginatedData.length - 1;
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const getDataSession = async (page) => {
    try {
      const nextPageToFetch = nextPage || page;
      const response = await webAnalyticsSession(sessionUser.access, webinarId, nextPageToFetch);
  
      if (response.status_code === 500 && response.status === "error" && response.message === "webinar not found") {
        navigate(routerPath.errorpage);
        console.error("Webinar not found");
      } else {
  
        getData();
        SetSessionData(response.webinar_attendee_data);
        setTotalRows(response.total_pages);
        setTotalData(response.webinar_attendee_data_len);
        setShowPage(true);
        setShowPageSea(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  React.useEffect(() => {
    getDataSession();
  }, []);
  const handleChangeSession = (event, page) => {
    const totalPages = Math.ceil(totalRowssession / rowsPerPageSessiion);
    getDataSessionPagination(page);
    setPageSession(page);
    setShowPage(false);
    setShowPageSea(true);
    if (page === totalPages) {
      getDataSession(page + 1);
    }
  };
  const getDataSessionPagination = async (page) => {
    try {
      const nextPageToFetch = nextPage || page;
      await webAnalyticsSession(sessionUser.access, webinarId, nextPageToFetch)
        .then((value) => {
          SetSessionData(value.webinar_attendee_data);
          setTotalRows(value.total_pages);
          setShowPage(true);
          setShowPageSea(false);
        })
        .catch((err) => {
          getData();
          console.error("error", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const exportToExcel = async (nextPageToFetch) => {
    try {
      const response = await getSessionExcel(
        sessionUser.access,
        webinarId,
        nextPageToFetch
      );

      const data = response.all_session_data;
      const formattedData = sessionData.map((item, index) => ({
        "No.of series": index + 1,
        Session: item.session_date.toString(),
        Registrants: item.registrants,
        "Webinar Attendees": item.webinar_attendees,
        "Reply Attendees": item.reply_attendees,
        "Reply Button Clicks": item.reply_button_clicks,
      }));
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sessions");

      XLSX.writeFile(workbook, "sessions.xlsx", {
        bookType: "xlsx",
        mimeType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  return (
    <>
      <Grid container >
        <Grid item className="session-container">
          <Stack direction={"row"} justifyContent={"space-between"}>
           
            <Typography
               variant="h4"
              gutterBottom
              style={{ textAlign: "start",    fontWeight: "500" }}
            >
              Sessions ({totalData})
            </Typography>
            <FaDownload onClick={exportToExcel} className="downloadExc" />
          </Stack>
          <div style={{ overflowX: "auto!important" }}>
            <TableContainer
              sx={{
               
                overflowX: "auto!important",
                marginTop: "12px",
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      No.of series
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      Session
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      Registrants
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      Webinar Attendees
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      Reply Attendees
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      align="center"
                      sx={{
                        border:
                          "1.94px solid rgb(117 114 116 / 31%) !important;",
                        width: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: " #6b59cc",
                      }}
                    >
                      Reply Button Clicks
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessionData.map((item, index) => (
                    <TableRow key={`${startIndex + index}row.name`}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                        }}
                      >
                        {(pagesession - 1) * rowsPerPage + index + 1}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                        }}
                      >
                        {item.session_date}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                        }}
                      >
                        {item.registrants}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                        }}
                      >
                        {item.webinar_attendees}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                        }}
                      >
                        {item.reply_attendees}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: "center",
                          fontSize: "15px",
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                        }}
                      >
                        {item.reply_button_clicks}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Stack direction={"row"} justifyContent={"center"} mt={4}>
            <Pagination
              count={totalRows}
              page={pagesession}
              onChange={handleChangeSession}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
