import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import "./style.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Typography } from "@mui/material";
import Layout from "../common/layout";
import { useSelector } from "react-redux";
import { chatReport } from "../../utils/apis/apis";
import { useParams } from "react-router-dom";
export default function ChatReports() {
  const { webinarId } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  const [chatData, SetChatData] = React.useState([]);
  const getData = async () => {
    try {
      await chatReport(sessionUser.access, webinarId)
        .then((value) => {
          SetChatData(value.data);
        })
        .catch((err) => {
          console.error("err", err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid>
        <Layout showSideBar active={0}>
          <div className="container-chatReport">
            <Grid className="gridPadding">
              <div className="heading-chatreport">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    textAlign: "start",
                    fontSize: "22",
                    fontWeight: "800",
                  }}
                >
                  Chat Report ({chatData.length})
                </Typography>
              </div>
              <TableContainer
                className="tablecontainer tablechatreport"
                sx={{ height: "87vh", border: "none" }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        S.no
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Date and Time
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Video Time
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        align="center"
                        sx={{
                          border:
                            "1.94px solid rgb(117 114 116 / 31%) !important;",
                          width: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: " #6b59cc",
                        }}
                      >
                        Message
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {chatData.length > 0 ? (
    chatData.map((row, i) => (
        <TableRow key={`${i}chatData`}>
            <TableCell
                className="table-celltr table-celltr1"
                component="th"
                scope="row"
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    border: "1.94px solid rgb(117 114 116 / 31%) !important;",
                }}
            >
                {i + 1}
            </TableCell>
            <TableCell
                className="table-celltr table-celltr1"
                component="th"
                scope="row"
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    border: "1.94px solid rgb(117 114 116 / 31%) !important;",
                }}
            >
                {row.user_name}
            </TableCell>
            <TableCell
                className="table-celltr table-celltr1"
                component="th"
                scope="row"
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    border: "1.94px solid rgb(117 114 116 / 31%) !important;",
                }}
            >
                {row.created_at}
            </TableCell>
            <TableCell
                className="table-celltr table-celltr1"
                component="th"
                scope="row"
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    border: "1.94px solid rgb(117 114 116 / 31%) !important;",
                }}
            >
                {row.session_time}
            </TableCell>
            <TableCell
                className="table-celltr table-celltr1"
                component="th"
                scope="row"
                sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    border: "1.94px solid rgb(117 114 116 / 31%) !important;",
                }}
            >
                {row.message}
            </TableCell>
        </TableRow>
    ))
) : (
    <TableRow>
        <TableCell colSpan={5} align="center">
            No chat messages available.
        </TableCell>
    </TableRow>
)}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </div>
        </Layout>
      </Grid>
    </>
  );
}
