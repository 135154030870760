import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
import "../landingPage/style.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../store/session";
const ThankYouPage = ({ history }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { webinarId } = useParams();
  const sessionUser = useSelector((state) => state.session.user);
  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (sessionUser.refresh) {
        navigate(routerPath.home);
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("webinarId");
        dispatch(sessionActions.logout());
        setTimeout(() => {
          navigate(`${routerPath.webinarJoin}/${webinarId}`);
          window.location.reload(true);
        }, 3000);
      }
    }, 3000);
    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [history]);
  return (
    <div
      className="thank-you"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <CheckCircleOutlineIcon
          sx={{
            fontSize: "5rem",
            color: "white",
            borderRadius: "12pc",
            backgroundColor:'green'
          }}
        ></CheckCircleOutlineIcon>
      </div>
      <div style={{ textAlign: "center" }}>
        <h1 className='thankyoufont'  style={{color: "whitesmoke",}}>Thank you for attending webinar</h1>
      </div>
    </div>
  );
};

export default ThankYouPage;
