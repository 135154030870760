import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Avatar, Button, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePassword } from "../../utils/apis/apis";
import { useNavigate } from "react-router-dom";
import routerPath from "../../utils/router/routerPath";
const ChangePassword = () => {
  const sessionUser = useSelector((state) => state.session.user);
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("old_password", data.oldPassword);
    formDataToSend.append("new_password", data.newPassword);
    try {
      await changePassword(sessionUser.access, formDataToSend)
        .then((value) => {
          if (value.status === "success") {
            console.log("response", value);
            setData({
              oldPassword: "",
              newPassword: "",
            });
            toast.success(value.message);
          } else {
            toast.error(value.message);
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    } catch (error) {
      navigate(routerPath.home);
      console.error(error);
    }
  };

  const isSubmitDisabled = !(data.oldPassword && data.newPassword);
  console.log(isSubmitDisabled, "isSubmitDisabled");

  return (
    <>
      <div className="heading-events">
        <Typography variant="h4" gutterBottom style={{ textAlign: "start" }}>
          Welcome To Change Password
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <TextField
            label="Enter Old Password"
            type={showOldPassword ? "text" : "password"}
            variant="standard"
            fullWidth
            name="oldPassword"
            value={data.oldPassword}
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleToggleOldPasswordVisibility}
                  edge="end"
                  aria-label="toggle oldPassword visibility"
                >
                  {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TextField
            label="Enter New Password"
            type={showNewPassword ? "text" : "password"}
            variant="standard"
            fullWidth
            name="newPassword"
            value={data.newPassword}
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleToggleNewPasswordVisibility}
                  edge="end"
                  aria-label="toggle confirm newPassword visibility"
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <TextField
            style={{ visibility: "hidden" }}
            label="Enter Old Password"
            type={showOldPassword ? "text" : "password"}
            variant="standard"
            fullWidth
            name="oldPassword"
            value={data.oldPassword}
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleToggleOldPasswordVisibility}
                  edge="end"
                  aria-label="toggle oldPassword visibility"
                >
                  {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{
              backgroundColor: "var(--themecolor)",
              fontWeight: "900",
              marginTop: "-5rem",
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;
