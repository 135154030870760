import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import "../events/style.css";
import { textAlign } from "@mui/system";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import InputAdornment from "@mui/material/InputAdornment";
import {
  getDirectoryVideoList,
  getUploadVideoList,
  uploadVideoFile,
} from "../../utils/apis/apis";
import { useSelector } from "react-redux";
import VideoList from "../common/VideoList";
const EventVideo = ({
  values,
  errors,
  touched,
  dirty,
  isValid,
  setFieldValue,
  handleChange,
  handleSubmit,
  setOption,
  option,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedOptionError, setSelectedOptionError] = React.useState("");
  const [selectedOption, setSelectedOption] = useState(option);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedState, setSelectedState] = useState("Active");
  const sessionUser = useSelector((state) => state.session.user);
  const [progress, setProgress] = useState(100);
  const [showProgress, setShowProgress] = useState(false);
  const [uplodVideoLists, setUplodVideoLists] = useState([]);
  const [directoryVideoLists, setDirectoryVideoLists] = useState([]);
  const [errorVideo, setErrorVideo] = useState("");
  const handleMenuItemClick = (newState) => {
    setSelectedState(newState);
    handleClose();
  };
  useEffect(() => {
    videoListUpload();
  }, []);
  if (errors && errors?.uploadvideo && values?.uploadvideo != "") {
    errors.uploadvideo = "";
  }
  if (errors && errors?.directory && values?.directory != "") {
    errors.directory = "";
  }
  const videoListUpload = async () => {
    await getUploadVideoList(sessionUser.access)
      .then((val) => {
        console.log("aaaaaaaaaaaa", val.data);
        setUplodVideoLists(val.data);
        videoListDirectory();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const videoListDirectory = async () => {
    await getDirectoryVideoList(sessionUser.access)
      .then((val) => {
        setDirectoryVideoLists(val.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(percentCompleted);
    if (percentCompleted == 100) {
      setShowProgress(false);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const formData = new FormData();
      formData.append("name", fileName);
      formData.append("video", file);
      setShowProgress(true);

      uploadVideoFile(sessionUser.access, formData, onUploadProgress)
        .then((val) => {
          videoListUpload();
          setSelectedFile(null);
        })
        .catch((err) => {
          console.error("error:", err);
        });

      setSelectedFile(file);
      event.target.value = "";
    }
  };
  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    setOption(option);
    if (option != "urlPath") {
    }
    setFieldValue("urlPath", "");
    setFieldValue("directory", "");
    setFieldValue("directoryAndUploadedVideoData", "");
    setFieldValue("uploadvideo", "");
  };
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  return (
    <>
      <div className="heading-events">
        <Typography variant="h4" gutterBottom style={{ textAlign: "start" }}>
          Webinar Videos
        </Typography>
      </div>
      <div style={{ textAlign: "start", marginTop: "1.6rem" }}>
        {errors && (
          <div>
            <span style={{ marginLeft: "0px", color: "red", fontSize: "15px" }}>
              {option == "select" && errors.select}
              {option == "urlPath" && errors.urlPath}
              {option == "directory" && errors.directory}
              {option == "uploadvideo" && errors.uploadvideo}
            </span>
          </div>
        )}
        {showProgress && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
        )}
        <FormControl sx={{ mr: 1, minWidth: "100%" }}>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "34px;", background: "#f1f4ff;" }}
          >
            <MenuItem value={"select"}>
              <p style={{ textAlign: "start", fontSize: "15px" }}>
                Choose Options
              </p>
            </MenuItem>
            <MenuItem value={"uploadvideo"}>
              <p style={{ textAlign: "start", fontSize: "15px" }}>
                Use upload Video
              </p>
            </MenuItem>
            <MenuItem value={"directory"}>
              <p style={{ textAlign: "start", fontSize: "15px" }}>
                Use Directory
              </p>
            </MenuItem>
          </Select>
        </FormControl>
        <div>
          {selectedOption === "uploadvideo" && (
            <div
              style={{ marginTop: "20px" }}
              className="uploadvideo-container"
            >
              {errorVideo && <p style={{ color: "red" }}> {errorVideo} </p>}
              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid
                  item
                  xs={5}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={2}
                  style={{ width: "100%" }}
                >
                  <div>
                    <input
                      type="file"
                      id="videoFile"
                      inputProps={{ accept: "video/*" }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="videoFile" className="upload-button">
                      Choose File
                    </label>
                  </div>
                </Grid>
                <Grid item xs={7} sm={9} md={10} lg={10} xl={10}>
                  <input
                    type="text"
                    value={selectedFile ? selectedFile.name : ""}
                    placeholder="File path"
                    style={{ width: "98%" }}
                    readOnly
                  />
                </Grid>
              </Grid>
              <VideoList
                videoList={uplodVideoLists}
                defultValueSelected={values.directoryAndUploadedVideoData}
                videoType={"Use uploded video"}
                setProcess={(val) => {
                  videoListUpload();
                }}
               
                selectedValue={({ videoType, videoId }) => {
                  setFieldValue("uploadvideo", videoType);
                  setFieldValue("directoryAndUploadedVideoData", {
                    videoType,
                    videoId,
                  });
                }}
              />
            </div>
          )}
          {selectedOption === "directory" && (
            <VideoList
              videoList={directoryVideoLists}
              defultValueSelected={values.directoryAndUploadedVideoData}
              videoType={"Use Directory"}
              setProcess={(val) => {
                videoListUpload();
              }}
              selectedValue={({ videoType, videoId }) => {
                setFieldValue("directory", videoType);
                setFieldValue("directoryAndUploadedVideoData", {
                  videoType,
                  videoId,
                });
              }}
            />
          )}
        </div>
      </div>

      <p style={{ margin: "2rem 0rem" }}>
        <b>Please Note</b>: You must select an uploaded video in order for it to
        be viewable in the webinar watch room. Uploaded videos may require
        several minutes to process before being viewable.
      </p>
    </>
  );
};

export default EventVideo;
