import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useSelector } from "react-redux";
import { getuserDeta, profileUpdate } from "../../utils/apis/apis";
import { toast } from "react-toastify";
import routerPath from "../../utils/router/routerPath";
import { useNavigate } from "react-router-dom";

const MyDetails = () => {
  const navigate = useNavigate();
  const sessionUser = useSelector((state) => state.session.user);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    const webinarId = localStorage.getItem("webinarId");
    const formDataToSend = new FormData();
    formDataToSend.append("first_name", formData.firstName);
    formDataToSend.append("last_name", formData.lastName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone_number", formData.number);
    try {
      const response = await profileUpdate(sessionUser.access, formDataToSend);
      if (response.status === "success") {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      navigate(routerPath.home);
      console.error("API error:", error);
    }
  };
  const getuserDetails = async () => {
    try {
      await getuserDeta(sessionUser.access)
        .then((value) => {
          setFormData({
            firstName: value.data.first_name,
            lastName: value.data.last_name,
            email: value.data.email,
            number: value.data.phone_number,
          });
        })
        .catch((err) => {
          console.error("error", err);
        });
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    getuserDetails();
  }, []);
  return (
    <>
      <div className="heading-events">
        <Typography variant="h4" gutterBottom style={{ textAlign: "start" }}>
          Personal Information
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <TextField
            label="FirstName"
            name="firstName"
            type="text"
            variant="standard"
            fullWidth
            value={formData.firstName}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <PersonIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TextField
            label="LastName"
            name="lastName"
            type="text"
            variant="standard"
            fullWidth
            value={formData.lastName}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <PersonIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TextField
            label="Email"
            name="email"
            type="text"
            variant="standard"
            fullWidth
            disabled
            value={formData.email}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <MarkEmailReadIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TextField
            label="Number"
            name="number"
            type="tel"
            variant="standard"
            fullWidth
            value={formData.number}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <CallIcon />
                </IconButton>
              ),
            }}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            style={{ backgroundColor: "var(--themecolor)", fontWeight: "900" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MyDetails;
