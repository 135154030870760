import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import TitleIcon from "@mui/icons-material/Title";
import InputAdornment from "@mui/material/InputAdornment";
const EventGeneralInfo = ({ values, errors, handleChange, webinarId }) => {
  return (
    <>
      <Box className="container-details">
        <div className="heading-events">
          <Typography variant="h4" gutterBottom style={{ textAlign: "start" }}>
            Webinar Details
          </Typography>
        </div>
        <div className="display-switch ">
          <TextField
            id="standard-textarea"
            label="Name"
            placeholder="Enter Name"
            name="name"
            value={values.name}
            error={!!errors.name}
            onChange={handleChange}
            variant="standard"
            className="field-box "
            sx={{ width: "100%", margin: "9px 0px 12px 0px; " }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <span style={{ color: "red", fontSize: "13px" }}>{errors.name}</span>
        </div>
      </Box>
      <div className="display-switch">
        <TextField
          id="standard-textarea"
          label="Title"
          placeholder="Enter Title"
          name="title"
          value={values.title}
          error={!!errors.title}
          onChange={handleChange}
          variant="standard"
          className="field-box "
          sx={{ width: "100%", margin: "9px 0px 12px 0px; " }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <TitleIcon />
              </InputAdornment>
            ),
          }}
        />
        <span style={{ color: "red", fontSize: "13px" }}>{errors.title}</span>
      </div>
    </>
  );
};
export default EventGeneralInfo;
