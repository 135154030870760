import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Drawer, Grid, List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import images from "../../../utils/constant/images";
import colors from "../../../utils/constant/colors";
import { useNavigate, useParams } from "react-router-dom";
import routerPath from "../../../utils/router/routerPath";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupAdd from "@mui/icons-material/GroupAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../../store/session";
import { toast } from "react-toastify";
import "../layout/style.css";
import { logOutUser } from "../../../utils/apis/apis";
import ComputerIcon from "@mui/icons-material/Computer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const pages = ["Products", "Pricing", "Blog"];
function Header({ buttonName, active }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(routerPath.home);
  const [open, setOpen] = React.useState(false);
  const { webinarId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const locationss = useLocation();
  const sessionUser = useSelector((state) => state.session.user);
  const isVideoPage = locationss.pathname === `/webinarView/${webinarId}`;
  const isVideoPage1 = locationss.pathname === `/webinarJoin/${webinarId}`;
  const settings = [
    sessionUser?.first_name
      ? sessionUser.first_name + " " + sessionUser?.last_name
      : sessionUser?.full_name,
    sessionUser?.email ? sessionUser.email : sessionUser?.guest_email || null,
  ].filter(Boolean);

  const settings2 = [
    sessionUser?.first_name
      ? sessionUser?.first_name + " " + sessionUser?.last_name
      : sessionUser?.full_name,
    sessionUser?.email ? sessionUser?.email : sessionUser?.guest_email,
    "logout",
  ];
  const [isLeave, setIsLeave] = React.useState(isVideoPage1);
  const [isConfirmationOpen, setConfirmationOpen] = React.useState(false);
  const [showTxtdd, setShowtextdd] = React.useState(true);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setOpen(false);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [initialRender, setInitialRender] = React.useState(true);
  const [isAdminUser, setIsAdminUser] = React.useState(" ");
  React.useEffect(() => {
    const userInfo = localStorage.getItem("user");

    if (userInfo) {
      const convertData = JSON.parse(userInfo);
      setIsAdminUser(convertData?.user_role === "admin_user");
      dispatch(sessionActions.setUser(convertData));
    }
    setInitialRender(false);
  }, [dispatch]);

  if (initialRender) {
    return null;
  }
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const changeNavigation = () => {
    if (buttonName == "Login") {
      navigate("/login");
    } else {
      navigate("/signup");
    }
  };
  const handleItemClick = (path) => {
    setActiveItem(path);
    navigate(path);
  };
  const logout = async () => {
    try {
      if (sessionUser.refresh) {
        const formData = new FormData();
        formData.append("refresh_token", sessionUser.refresh);
        await logOutUser(sessionUser.access, formData)
          .then((value) => {
            if (value.status === "success") {
              console.log("logout");
              toast.success(value.message);
              dispatch(sessionActions.logout());
              localStorage.removeItem("webinarId");
              localStorage.removeItem("user");
              navigate(routerPath.login);
              window.location.reload(true);
            }
          })
          .catch((err) => {
            console.error("error", err);
            dispatch(sessionActions.logout());
            localStorage.removeItem("webinarId");
            localStorage.removeItem("user");
            navigate(routerPath.login);
            window.location.reload(true);
          });
      } else {
        toast.success("Logout Successfully!!");
        dispatch(sessionActions.logout());
        localStorage.removeItem("webinarId");
        localStorage.removeItem("user");
        navigate(`${routerPath.webinarJoin}/${webinarId}`);
        window.location.reload(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.success("Logout Successfully!!");
      dispatch(sessionActions.logout());
      localStorage.removeItem("webinarId");
      localStorage.removeItem("user");
      navigate(routerPath.login);
      window.location.reload(true);
    }
  };
  const sidebarItems = [
    {
      path: routerPath.home,
      icon: (val) => (
        <HomeIcon
          sx={{
            color:
              active == 0
                ? colors.white
                : location.pathname === val
                ? colors.white
                : "",
          }}
        />
      ),
    },

    {
      path: routerPath.events,
      icon: (val) => (
        <ComputerIcon
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
    {
      path: routerPath.normaluser,
      icon: (val) => (
        <PeopleAltIcon
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
    {
      path: routerPath.webinarGhostUser,
      icon: (val) => (
        <GroupAdd
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
    {
      path: routerPath.setting,
      icon: (val) => (
        <SettingsIcon
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
  ];
  const sidebarItemsUsers = [
    {
      path: routerPath.home,
      icon: (val) => (
        <HomeIcon
          sx={{
            color:
              active == 0
                ? colors.white
                : location.pathname === val
                ? colors.white
                : "",
          }}
        />
      ),
    },

    {
      path: routerPath.events,
      icon: (val) => (
        <ComputerIcon
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
    {
      path: routerPath.webinarGhostUser,
      icon: (val) => (
        <GroupAdd
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
    {
      path: routerPath.setting,
      icon: (val) => (
        <SettingsIcon
          sx={{
            color: location.pathname === val ? colors.white : "",
          }}
        />
      ),
    },
  ];
  const handleLogout = () => {
    logout();
  };
  const handleLeave = () => {
    setConfirmationOpen(true);
  };
  const handleConfirmLeave = async () => {
    try {
      if (sessionUser.refresh) {
        const formData = new FormData();
        formData.append("refresh_token", sessionUser.refresh);
        await logOutUser(sessionUser.access, formData)
          .then((value) => {
            if (value.status === "success") {
              console.log("logout");
              toast.success(value.message);
              dispatch(sessionActions.logout());
              navigate(routerPath.home);
            }
          })
          .catch((err) => {
            console.error("error", err);
            dispatch(sessionActions.logout());
            navigate(routerPath.home);
          });
      } else {
        toast.success("Leave Webinar Successfully!!");
        dispatch(sessionActions.logout());
        window.location.reload(true);
        localStorage.removeItem("webinarId");
        localStorage.removeItem("user");
        navigate(`${routerPath.webinarJoin}/${webinarId}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.success("Leave Webinar Successfully!!");
      dispatch(sessionActions.logout());
      window.location.reload(true);
      localStorage.removeItem("webinarId");
      localStorage.removeItem("user");
      navigate(routerPath.login);
    }
  };

  const handleCancelLeave = () => {
    setConfirmationOpen(false);
  };
  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: colors.white }}
      ></AppBar>
      <Grid item md={12}>
        <Toolbar disableGutters sx={{ padding: "0px 24px" }}>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img className="logo" src={images.logo} alt="Logo" />
          </Box>
          <Grid
            container
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={2}
              md={2}
              style={{ display: sessionUser?.refresh ? "" : "none" }}
            >
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon sx={{ color: colors.black }} />
                </IconButton>

                <Drawer
                  open={Boolean(anchorElNav)}
                  onClick={handleCloseNavMenu}
                >
                  <List>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      {isAdminUser
                        ? sidebarItems.map((item, index) => (
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                px: 2.5,
                              }}
                              onClick={() => handleItemClick(item.path)}
                              key={index}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  justifyContent: "center",

                                  padding: "10px",
                                  borderRadius: "10px",
                                  backgroundColor:
                                    active == 0 && index == 0
                                      ? colors.primary
                                      : location.pathname === item.path
                                      ? colors.primary
                                      : "",
                                }}
                              >
                                {item.icon(item.path)}
                              </ListItemIcon>
                            </ListItemButton>
                          ))
                        : sidebarItemsUsers.map((item, index) => (
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                px: 2.5,
                              }}
                              onClick={() => handleItemClick(item.path)}
                              key={index}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  justifyContent: "center",

                                  padding: "10px",
                                  borderRadius: "10px",
                                  backgroundColor:
                                    active == 0 && index == 0
                                      ? colors.primary
                                      : location.pathname === item.path
                                      ? colors.primary
                                      : "",
                                }}
                              >
                                {item.icon(item.path)}
                              </ListItemIcon>
                            </ListItemButton>
                          ))}

                      <ListItemButton
                        sx={{
                          minHeight: 48,

                          px: 2.5,
                        }}
                        onClick={logout}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            borderRadius: "10px",
                            justifyContent: "center",

                            padding: "10px",
                          }}
                        >
                          <LogoutIcon />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Drawer>
              </Box>
            </Grid>
            <Grid xs={7} md={8} item>
              <Box
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img
                  style={{ width: "200px" }}
                  src={images.logo}
                  alt="Logo"
                  className="logo-small"
                />
              </Box>
            </Grid>
            <Grid xs={3} md={2} item>
              {isVideoPage && (
                <div
                  className="bg-btncolor m-leave showbtn1"
                  style={{ marginBottom: "-36px" }}
                >
                  <Button variant="contained" onClick={handleLeave}>
                    Leave
                  </Button>
                </div>
              )}
              <Box
                sx={{
                  flexGrow: 0,
                  justifyContent: "space-between",
                  textAlign: "end",
                }}
              >
                {sessionUser ? (
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={
                        sessionUser?.first_name
                          ? sessionUser?.first_name?.toUpperCase()
                          : sessionUser?.full_name?.toUpperCase()
                      }
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                ) : buttonName ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      changeNavigation();
                    }}
                    sx={{
                      color: "white",
                      backgroundColor: "#6b59cc",
                      padding: "4px 10px",
                      fontWeight: "700",
                    }}
                    className="nav-btn btn-sgn"
                  >
                    {buttonName}
                  </Button>
                ) : null}

                <Menu
                  sx={{ mt: "45px", height: "677px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    style: {},
                  }}
                >
                  {isVideoPage
                    ? settings.map((setting, index) => (
                        <MenuItem
                          key={index}
                          onClick={
                            setting === "logout"
                              ? handleLogout
                              : handleCloseUserMenu
                          }
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))
                    : settings2.map((setting, index) => (
                        <MenuItem
                          key={index}
                          onClick={
                            setting === "logout"
                              ? handleLogout
                              : handleCloseUserMenu
                          }
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                  {isVideoPage && (
                    <div
                      className="bg-btncolor showbtn2"
                      style={{ marginBottom: "-36px", padding: "0px 14px" }}
                    >
                      <Button variant="contained" onClick={handleLeave}>
                        Leave
                      </Button>
                    </div>
                  )}
                  <Dialog
                    open={isConfirmationOpen}
                    onClose={handleCancelLeave}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Leave Webinar?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to leave the webinar?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <div className="leavecancel">
                        <Button onClick={handleCancelLeave} color="primary">
                          Cancel
                        </Button>
                      </div>
                      <div className="leaveconfirm">
                        <Button
                          onClick={handleConfirmLeave}
                          color="primary"
                          autoFocus
                        >
                          Confirm
                        </Button>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
      </Grid>
    </>
  );
}
export default Header;
