import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getAllghostuserapi } from "../../utils/apis/apis";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const EventsGhostUsers = ({
  onChildData,
  values,
  ghostUsers = [],
  editable,
}) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]); 
  const sessionUser = useSelector((state) => state.session.user);
  const [chatData, setChatData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setChatData(values.selected_ghost_user);
  }, [values.selected_ghost_user]);

  React.useEffect(() => {
    if (editable) {
      setPersonName(ghostUsers);
    }
  }, [ghostUsers]);
  const handleChange = (event) => {
    const getIDS = event.target.value.map((val) => val.id);
    console.log("ffffffffff", getIDS);
    onChildData(getIDS);
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    const selectedOptionIds = value.map((selected) => {
      const matchedUser = chatData.find(
        (user) => user.first_name + " " + user.last_name === selected
      );
      return matchedUser ? matchedUser.id : null;
    });

    setSelectedIds(selectedOptionIds.filter((id) => id !== null));
  };
  const getAllghostuser = async () => {
    try {
      const response = await getAllghostuserapi(sessionUser.access);
      setChatData(response.data);
      setLoading(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    getAllghostuser();
  }, []);
  React.useEffect(() => {
    setChatData(chatData);
  }, [chatData]);
  return (
    <div>
      <div className="heading-events">
        <Typography variant="h4" gutterBottom className="heading-details">
          Ghost Users
        </Typography>
      </div>
      <div>
        <FormControl sx={{ m: 1, width: "100%" }}>
          <InputLabel
            id="demo-multiple-chip-label"
            sx={{ backgroundColor: "white" }}
          >
            Select Ghost Users
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1.5,

                  overflow: "auto",
                  maxHeight: "150px",
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value.first_name + " " + value.last_name}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {chatData.map((name) => (
              <MenuItem
                key={name.id}
                value={name}
                style={getStyles(name, personName, theme)}
              >
                {name.first_name + " " + name.last_name}
              </MenuItem>
            ))}
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default EventsGhostUsers;
