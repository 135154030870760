import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Login from "../../components/auth/Login";
import SignUp from "../../components/auth/SignUp";
import Events from "../../components/events";
import Home from "../../components/home";
import LandingPage from "../../components/landingPage";
import Settings from "../../components/setting";
import routerPath from "./routerPath";
import * as sessionActions from "../../store/session";
import WebinarView from "../../components/webinarView";
import WebinarJoin from "../../components/webinarView/WebinarJoin";
import ChatReports from "../../components/chatReport";
import WebinarAnalytics from "../../components/webinarAnalytics";
import ErrorPage from "../../components/pageNotFound";
import ThankYouPage from "../../components/thankYouPage";
import RecoverPassword from "../../components/auth/RecoverPassword";
import ForgetPassword from "../../components/auth/ForgetPassword";
import WebinarEdit from "../../components/webinarEdit";
import DummyUsers from "../../components/dummyUsers";
import NormalUser from "../../components/NormalUser";
export function RequireAuth({ children }) {
  const sessionUser = useSelector((state) => state.session.user);
  const isAuthenticated = sessionUser?.refresh ? true : false; // your logic here
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}
export function RequireJoinWebinar({ children }) {
  const sessionUser = useSelector((state) => state.session.user);
  const isAuthenticated = !!sessionUser;
  const location = useLocation();
  const { webinarId } = useParams();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    queryParams.forEach((value, key) => {
      currentUrl.searchParams.set(key, value);
    });
    window.history.replaceState({}, '', currentUrl);
  }, [queryParams]);

  if (!isAuthenticated) {
    console.log("Redirecting due to not being authenticated.");
    return (
      <Navigate
        to={`${routerPath.webinarJoin}/${webinarId}`}
        state={{ from: location }}
      />
    );
  }

  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { queryParams });
    }
    return child;
  });
}
 function Routers() {
  const dispatch = useDispatch();
  const [initialRender, setInitialRender] = React.useState(true);
  const [isAdminUser, setIsAdminUser] = React.useState(" ");
   React.useEffect(() => {
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      const convertData = JSON.parse(userInfo);
      setIsAdminUser(convertData?.user_role === "admin_user");
      dispatch(sessionActions.setUser(convertData));
    }

    setInitialRender(false);
  }, [dispatch]);

  if (initialRender) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path={routerPath.login} element={<Login />} />
        <Route path={routerPath.signup} element={<SignUp />} />
        <Route
          path={routerPath.home}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path={routerPath.events}
          element={
            <RequireAuth>
              <Events  />
            </RequireAuth>
          }
        />
        <Route
          path={isAdminUser ? routerPath.normaluser : routerPath.home}
          element={isAdminUser ? <NormalUser /> : null}
        />
        <Route
          path={`${routerPath.chatReport}/:webinarId`}
          element={<ChatReports />}
        />
        <Route
          path={ routerPath.webinarGhostUser }
          element={<DummyUsers />}
        />
        <Route
          path={`${routerPath.webinarAnalytics}/:webinarId`}
          element={<WebinarAnalytics />}
        />
        <Route path={routerPath.setting} element={<Settings />} />
        <Route
          path={`${routerPath.webinarAttendSuccess}/:webinarId`}
          element={<ThankYouPage />}
        />
        <Route
          path={`${routerPath.webinarView}/:webinarId`}
          element={
            <RequireJoinWebinar>
              <WebinarView />
            </RequireJoinWebinar>
          }
        />
        <Route
          path={`${routerPath.webinarEdit}/:webinarId`}
          element={
            <RequireJoinWebinar>
              <WebinarEdit />
            </RequireJoinWebinar>
          }
        />
        <Route
          path={`${routerPath.webinarJoin}/:webinarId`}
          element={<WebinarJoin />}
        />
        <Route path={`${routerPath.errorpage}`} element={<ErrorPage />} />
        <Route
          path={`${routerPath.recoverPassword}`}
          element={<RecoverPassword />}
        />
        <Route
          path={`${routerPath.forgetPassword}/:userId/:userToken`}
          element={<ForgetPassword />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Routers;
